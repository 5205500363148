import React, { createContext } from "react";
import { getFromLS, setToLS, deleteFromLS } from "../../utils/helpers/storage";
import { useLocation, useNavigate } from "react-router";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const location = useLocation();

  const navigate = useNavigate();

  const handleLogin = async (user) => {
    // store the user in local storage.
    setToLS("User", user);
    const origin = location.state?.from?.pathname || "/home";
    navigate(origin);
  };

  const handleLogout = () => {
    deleteFromLS("User", null);
    navigate("/login");
  };

  const value = {
    user: getFromLS("User"),
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
