import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link ,useParams} from "react-router-dom";
import { Routes } from "react-router-dom";
import { useSidebarContext } from "./sidebar-context";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CaseHistorView from "../../../views/case-detail/case-history-view";
import styled from "styled-components";
import MainCaseDetailNavigataion from "../main-case-details-navigation";
import CaseDetailNavigation from "../../case-detail/case-details-navigation";
import { DYNAMIC_FIXEDDATA } from "../../../utils/constants/entity-types";
import { dynamicEntity } from "../../../services/dynamic-data-service";
import { setToSS, getFromSS } from "../../../utils/helpers/storage";
import { useLocation } from "react-router-dom";
export default function SideBar({ children }) {
  const [showSubNavHome, setShowSubNavHome] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [dynamicMenuItems, setDynamicMenuItems] = useState([]);
  const { caseID } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseType = queryParams.get("caseType");
  const caseSubType = queryParams.get("caseSubType");
  const CallApi = async () => {
 
    try {
      const response = await dynamicEntity(caseType, caseSubType, caseID, "1");
      setToSS(caseID, response.data.dynamicEntities);
      setDynamicMenuItems(response.data.dynamicEntities); // Log the response data
    } catch (error) {
       // Log any errors
    }
  };
  useEffect(() => {
    document.title = `Case ${caseID}`;
    // Function to handle the beforeunload event
    const handleBeforeUnload = (event) => {
      // Perform your cleanup logic here, e.g., removing items from localStorage
      localStorage.removeItem(`case_${caseID}`);
      // Customize the message displayed to the user
      event.returnValue = "Are you sure you want to leave this page?";

    };

    // Attach the beforeunload event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [caseID]);
  useEffect(() => {
    const storedData = getFromSS(caseID);
    if (storedData) {
      setDynamicMenuItems(storedData);
    } else {
      CallApi(); // Call the Test function to fetch and store the data
    }
  }, []);

  const uniqueParents = Array.from(
    new Set(dynamicMenuItems.map((menuItem) => menuItem.parent))
  );
  const sortedDynamicMenuItems = dynamicMenuItems.slice().sort((a, b) => {
    // Sort "F" entity types to the top
    if (a.entityType === DYNAMIC_FIXEDDATA && b.entityType !== DYNAMIC_FIXEDDATA) {
      return -1;
    } else if (a.entityType !== DYNAMIC_FIXEDDATA && b.entityType === DYNAMIC_FIXEDDATA) {
      return 1;
    }
    return 0;
  });
  const handleMenuItemClick = (menuItem) => {
    console.log("Selected Menu Item Data:", menuItem);
    // Perform any other actions you want on menu item click
  };

  return (
    <>
      <MainCaseDetailNavigataion
        homeClick={() => {
          setShowSubNavHome(!showSubNavHome);

        }}
      />
      <DivWrapper id="app">
        <StyledSidebar collapsed={collapsed}>
          <Menu>
            <MenuItemWrapper
              icon={<MenuOutlinedIcon />}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            ></MenuItemWrapper>
            {uniqueParents.map((parent, index) => (
              <SubMenuWrapper
                key={index}
                label={parent}
                icon={<HomeOutlinedIcon />}
              >
                {sortedDynamicMenuItems
                  .filter((submenuItem) => submenuItem.parent === parent)
                  .map((submenuItem, subIndex) => (
                    <MenuItemWrapper
                      key={subIndex}
                      icon={<FiberManualRecordIcon fontSize="small" />}
                      component={
                        <Link
                          to={
                            submenuItem.entityType === DYNAMIC_FIXEDDATA
                              ? `/casedetail/${submenuItem.caseID}/${submenuItem.entityName}`
                              : `/casedetail/${submenuItem.caseID}/${submenuItem.entityName}/${submenuItem.detailsIndex}`
                          }
                          className="link"
                        ></Link>

                      }
                      onClick={() => handleMenuItemClick(submenuItem)} // Handle menu item click
                    >

                      {
                        (submenuItem.detailsIndex > 1 && submenuItem.orgID !== null)
                          ? `${submenuItem.entityLabel}(${submenuItem.orgID})(${submenuItem.detailsIndex})`
                          : (submenuItem.detailsIndex > 1 && submenuItem.orgID === null)
                            ? `${submenuItem.entityLabel}(${submenuItem.detailsIndex})`
                            : submenuItem.entityLabel
                      }
                    </MenuItemWrapper>
                  ))}
              </SubMenuWrapper>
            ))}
          </Menu>
        </StyledSidebar>
        <WorkArea>
          {showSubNavHome ? (
            <div className="header">
              <CaseDetailNavigation
                hide={() => {
                  setShowSubNavHome(!showSubNavHome);
                }}
              ></CaseDetailNavigation>
            </div>
          ) : null}

          {children}

        </WorkArea>
      </DivWrapper>
    </>
  );
}

const WorkArea = styled.section`
  width: 100vw;
`;

const StyledSidebar = styled(Sidebar)`
  .css-dip3t8 {
    background: ${({ theme }) => theme.palette.nav.main};
    height: 100vh;
  }
`;

const DivWrapper = styled.div`
  height: 100vh;
  display: flex;
`;

const MenuItemWrapper = styled(MenuItem)`
 
  color: ${({ theme }) => theme.palette.nav.textIcon};
  background: ${({ theme }) => theme.palette.nav.main};
`;

const SubMenuWrapper = styled(SubMenu)`
  color: ${({ theme }) => theme.palette.nav.textIcon};
`;
