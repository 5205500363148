import { useEffect, useState } from "react";
import LoginView from "./views/login-view/login-view";
import { ThemeProvider } from "styled-components";
import { getSolicitorSetting } from "./services/setting-service";
import Loader from "./components/shared/loader";
import styled from "styled-components";
import { Route, Routes,Outlet,useParams } from "react-router-dom";
import HomeView from "./views/home/home-view";
import AuthProvider from "./components/auth-provider/auth-provider";
import ProtectedRoute from "./components/auth-provider/protected-route";
import { useSubDomain } from "./hooks/useSubDomain";
import CaseStatusView from "./views/case-detail/case-status-view";
import CaseHistorView from "./views/case-detail/case-history-view";
import CaseNoteView from "./views/case-detail/case-note-view";
import CaseLetterView from "./views/case-detail/case-letter-view";
import CaseTaskView from "./views/case-detail/case-task-view";
import CaseDateView from "./views/case-detail/case-date-view";
import DynamicRoute from "./components/dynamic-entity/dynamic-route";
import { getFromSS } from "./utils/helpers/storage";
import { MyProSidebarProvider } from "./components/navigation/side-navbar/sidebar-context";
import CaseDetailLayout from "./components/shared/case-detail-layout";
import { Menu } from "devextreme-react";
function App() {
  const [setting, setSetting] = useState(null);
  const [dynamicMenuItems, setDynamicMenuItems] = useState([]);
  const { caseID } = useParams();
  useEffect(() => {
    async function fetchStoredData() {
      const checkDataAvailability = async () => {
        while (true) {
          const storedData = await getFromSS("15041");
          if (storedData) {
            return storedData;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
        }
      };

      const storedData = await checkDataAvailability();
      setDynamicMenuItems(storedData);
    }
      fetchStoredData();
  
  },  [caseID]);
  useEffect(() => {
    
    // using the subdomain get the client settings.
    async function GetClientSetting() {
      const { domain } = useSubDomain();

      // fetch the solictor settings, etc theme & login content)
      var solictorSettings = await getSolicitorSetting(domain);
      setSetting(solictorSettings.data);
    }

    if (!setting) {
      GetClientSetting();
    }
  });

  // temporary theme, this will be stored in the db eventually.
  const knightsBridge = {
    palette: {
      primary: {
        main: "#2d2d70",
        gradient1: "#2D2D70",
        gradient2: "#8181a9",
        contrastTextColour: "#FFFFFF",

      },
      secondary: {
        main: "#FFFFFF",
        contrastTextColour: "#2d2d70",
      },
      tertiary: {
        main: "#c0c0c0",
        contrastTextColour: "#000000",
      },
      nav: {
        main: "#323232",
        contrastTextColour: "#000000",
        onHoverBackground: "#0D7377",
        onHoverTextIcon: "#14FFEC",
        textIcon: "#ffffff"
      },
      subMenu: {
        main: "#323232",
        contrastTextColour: "#000000",
        onHoverBackground: "#0D7377",
        onHoverTextIcon: "#14FFEC",
        textIcon: "#ffffff"
      },
      caseNote: {
        main: "#ffffff",
        button: "#f5ba13",

      }
    },
  };

  const defaultTheme = {
    palette: {
      primary: {
        main: "#FFFFFF",
        gradient1: "#2D2D70",
        gradient2: "#8181a9",
        contrastTextColour: "#2d2d70",
      },
      secondary: {
        main: "#2d2d70",
        contrastTextColour: "#FFFFFF",
      },
    },
  };

  if (!setting) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <LoadingBackground>
          <Loader showLoader={true} />
        </LoadingBackground>
      </ThemeProvider>
    );
  }
  const getData = (data )=> {
  }
  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={knightsBridge}>
          <Routes>
            <Route path="login" element={<LoginView content={setting} />} />
            <Route path="/home" element={<ProtectedRoute />}>
              <Route index element={<HomeView onSubmit={getData}/>} />
            </Route>
            <Route path="*" element={<LoginView content={setting} />} />
            <Route
              path="/casedetail/:caseID"
              element={
                <CaseDetailLayout>
                 <ProtectedRoute>
                    <Outlet /> {/* This will render child routes */}
                  </ProtectedRoute>
                </CaseDetailLayout>
              }
            >
              <Route index element={<CaseHistorView />} />
              <Route path="CaseHistory" element={<CaseHistorView />} />
              <Route path="CaseTasks" element={<CaseTaskView />} />
              <Route path="CaseLetter" element={<CaseLetterView />} />
              <Route path="CaseNotes" element={<CaseNoteView />} />
              <Route path="CaseDates" element={<CaseDateView />} />
              <Route path="CaseStatus" element={<CaseStatusView />} />
              {dynamicMenuItems.map((menu) => (
                <Route
                  key={menu.entityName + menu.detailsIndex + menu.caseID}
                  path={menu.entityName + "/" + menu.detailsIndex}
                  element={
                    <DynamicRoute
                      entityType={menu.entityType}
                      entityName={menu.entityName}
                      detailIndex={menu.detailsIndex}
                      caseID={menu.caseID}
                      orgID={menu.orgID}
                      entityID={menu.id}
                      selectFromList={menu.selectFromList}
                      casePersonID={menu.casePersonID}
                      data={menu}
                    />
                  }
                />
              ))}
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}
export default App;

const LoadingBackground = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
`;
