import React from "react";
import HashLoader from "react-spinners/HashLoader";
import styled, { useTheme } from "styled-components";

export default function Loader({ showLoader }) {
  const theme = useTheme();
  if (showLoader) {
    return (
      <Loading>
        <HashLoader
          color={theme.palette.secondary.main}
          loading="true"
          size={130}
        />
      </Loading>
    );
  }
}

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
