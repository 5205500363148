import React, { useState, useCallback } from 'react'
import CustomGrid from '../../controls/grid';
import { Column, HeaderFilter, FilterRow, Pager, Paging, SearchPanel, Export, Editing, Lookup, ColumnChooser, ColumnChooserSelection, StateStoring } from 'devextreme-react/data-grid'
import historyData from '../../json/history-data';
import { CheckBox } from 'devextreme-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../modals/custom-modal';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Button from '../../controls/button';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { subFolder, feeEarner } from '../../json/sub-folder';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CrudModal from '../../modals/edit-history-modal';
import { setToLS } from '../../../utils/helpers/storage';
import { getFromLS } from '../../../utils/helpers/storage';
import { tabSuffix, desktopSuffix } from '../../../utils/constants/storageKeys';
import { Mobile, Desktop, Tab } from '../../../utils/constants/devices';
import "./case-history.css"
const allowedPageSizes = [10, 25, 50, 100];

export default function CaseHistoryGrid({ storeKey }) {
  const [showViewCaseHistory, setViewCaseHistory] = useState(false);
  const [showCrudModal, setCrudModal] = useState(false);
  const [mode, setMode] = useState("");
  const [title, setTitle] = useState("");
  const [caseID, setCaseID] = useState("");
  const [timeStamp, setTimeStamp] = useState("");
  const [numberOfUnits, setNumberOfUnits] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cost, setCost] = useState("");
  const device = useScreenSize();
  let storageKey;
  let mobile = false;
  if (device === Tab) {
    storageKey = storeKey + tabSuffix
    mobile = false;
  }
  else if (device === Desktop) {
    storageKey = storeKey + desktopSuffix
    mobile = false;
  }
  else if (device === Mobile) {
    mobile = true;
  }
  const onExporting = useCallback((e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
    e.cancel = true;
  }, []);
  function DataRow(e) {
    return (
      <React.Fragment>
        <tr className="main-row">
          <td>{e.data.Date}</td>
          <td>{e.data.Time}</td>
          <td>{e.data.FeeEarnerID}</td>
          <td>{e.data.Minutes}</td>
        </tr>
        <tr className="notes-row">
          <td colSpan="4
          "><div>{e.data.Narrative}</div></td>
        </tr>
      </React.Fragment>
    );
  }
  const FixedFeeEntyCheckboxCell = (props) => {
    return (
      <>
        <div className="checkbox-cell">
          <CheckBox />
        </div>
      </>
    );
  };
  const FileCell = (props) => {
    const { data } = props;

    const hasLinkedDocument = data.LinkedDocument; // Assuming you have a property named "LinkedDocument" that indicates the presence of a linked document
    const handleFileClick = () => {
      if (hasLinkedDocument) {
        const docs = [
          { uri: "https://calibre-ebook.com/downloads/demos/demo.docx", fileType: 'docx', fileName: "demo.docx" }, // Local File
        ];

        return <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{ height: 1000 }} />;
      }
    };
    return (
      <div className="File-logo" onClick={handleFileClick}>
        {hasLinkedDocument && <FontAwesomeIcon icon={faFile} />}
      </div>
    );
  };
  const onRowDblClick = (e) => {
    // Perform any additional actions you want on row double-click
    setViewCaseHistory(true);
  };


  const loadState = useCallback(() => {
    return getFromLS(storageKey);
  }, []);

  const saveState = useCallback((state) => {
    setToLS(storageKey, state);
  }, []);
  const addMenuItems = (e) => {
    let data, title, mode;
    if (e.target == 'content') {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
        text: ' Edit History Record',
        onItemClick: () => {
          data = e.row.data;
          title = "Update History Record";
          mode = "Update"
          itemClick(data, title, mode)

        }
      }, {
        text: 'Delete History Record',
        onItemClick: () => {
          data = e.row.data;
          title = "Delete History Record";
          mode = "Delete"
          itemClick(data, title, mode)
        }
      }
        , {
          text: 'Send File via beA Mail',
          onItemClick: () => {
            // Perform custom action 1
          }
        }
        , {
          text: 'Email File to',
          items: [
            { text: 'Facebook' },
            { text: 'Twitter' }],
          onItemClick: () => {
            // Perform custom action 1
          }
        }
        , {
          text: 'Delete History Record',
          onItemClick: () => {
            // Perform custom action 1
          }
        }
        , {
          text: 'Delete History Record',
          onItemClick: () => {
            // Perform custom action 1
          }
        }
      );
    }
  };

  const itemClick = (data, title, mode) => {
    setCrudModal(true)
    setMode(mode)
    setTitle(title)
    setCaseID(data.CaseId)
    setTimeStamp(data.Date + " " + data.Time)
    setNumberOfUnits(data.NoOfUnits)
    setQuantity(data.Quantity)
    setCost(data.Cost)
  };
  return (
    <div>
      {showCrudModal ? <CrudModal hide={() => setCrudModal(false)} name={mode} title={title} caseID={caseID} timeStamp={timeStamp} numberOfUnits={numberOfUnits} quantity={quantity} cost={cost}></CrudModal> : null}
      {showViewCaseHistory && <Modal icon={<FontAwesomeIcon icon={faEye} />} hide={() => setViewCaseHistory(false)} title="View Task" className="modal-dialog modal-dialog-centered" footer={
        <>
          <Button size='medium' variant='outlined' name='Close' onClick={() => setViewCaseHistory(false)}></Button>
          <Button type='submit' size='medium' variant='contained' name='Edit Case History'  ></Button>
        </>
      }></Modal>}
      <CustomGrid data={historyData} onRowDblClick={mobile ? onRowDblClick : null} mobileView={mobile ? DataRow : null} id="gridContainer" onExporting={onExporting} contextMenu={addMenuItems}>
        <Editing mode="cell" allowUpdating={true} />
        <FilterRow visible={!mobile} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <StateStoring enabled={!mobile} type="custom" customLoad={loadState} customSave={saveState} />
        <Column caption="Branch" dataField="Branch" dataType="string" allowEditing={false} visible={!mobile}></Column>
        <Column caption="Date" dataField="Date" dataType="date" ></Column>
        <Column caption="Time" dataField="Time" dataType="time"></Column>
        <Column caption="Fee Earner ID" dataField="FeeEarnerID" dataType="string" >
          <Lookup dataSource={feeEarner} /></Column>
        <Column caption="Narrative" dataField="Narrative" dataType="string" visible={!mobile}></Column>
        <Column dataField="Name" caption="Sub Folder" visible={!mobile} ><Lookup dataSource={subFolder} /></Column>
        <Column caption="Quantity" dataField="Quantity" visible={!mobile}  ></Column>
        <Column caption="No Of Units" dataField="NoOfUnits" visible={!mobile} ></Column>
        <Column caption="Fixed Fee Entry" cellRender={FixedFeeEntyCheckboxCell} visible={!mobile}></Column>
        <Column caption="Cost" dataField="Cost" allowEditing={false} visible={!mobile}></Column>
        <Column caption="File" cellRender={FileCell} visible={!mobile}></Column>
        <Column caption="Minutes" dataField="Minutes" allowEditing={false}  ></Column>
        <Column caption="Elapsed Minutes" dataField="ElapsedMinutes" allowEditing={false} visible={!mobile}></Column>
        <Export enabled={true} />
        <ColumnChooser enabled={true}>
          <ColumnChooserSelection />
        </ColumnChooser>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} />
      </CustomGrid>
    </div>
  )
}
