import React, { useEffect } from 'react'
import CustomGrid from '../../controls/grid';
import { Scrolling, RemoteOperations, Column, Grouping, GroupPanel, Summary, GroupItem, SearchPanel,Format } from 'devextreme-react/data-grid';
import { useAuth } from '../../../hooks/useAuth';
import { activeList } from '../../../services/case-service';
import { useState } from 'react';
import { CaseDetailTab } from '../../case-detail/case-detail-tab';
import CaseDuplicateAlert from '../../modals/alert-modals/case-duplicate-alert';
import CheckBox from '../../controls/checkbox';
import { locale } from 'devextreme/localization';
import "./active-list.css"
export default function ActiveListGrid() {
    const [activeListData, setActiveListData] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState([]);
    const [showCaseAlert, setShowCaseAlert] = useState(false);
    const { user } = useAuth();
    useEffect(() => {
        locale('en-GB');
        CallApi();
    }, []);
    const CallApi = async () => {
        try {
            const activelist = await activeList(user.userID);
            setActiveListData(activelist.data.caseInfo);
        }
        catch (error) {
           // Log any errors
        }
    };
    const handleRowDblClick = async (e) => {
        const caseID = e.data.id;
        setSelectedRowId(caseID);
        CaseDetailTab(caseID);
    };
    const strikeCheckboxCell = (props) => {
        const { data } = props;
        return (
          <div className="checkbox-cell">
            <CheckBox
              checked={data.hireCar}
            />
          </div>
        );
      };
    return (
        <div>
            {showCaseAlert && <CaseDuplicateAlert hide={() => { setShowCaseAlert(!showCaseAlert) }} caseID={selectedRowId}></CaseDuplicateAlert> }
            <CustomGrid data={activeListData} id="activeListGridContainer" keyExpr="id" onRowDblClick={handleRowDblClick} wordWrapEnabled={false}  >
                <RemoteOperations summary={true} />
                <Scrolling mode="virtual" />
                <SearchPanel visible={true} ></SearchPanel>
                <Grouping autoExpandAll={true} />
                <GroupPanel visible={true} />
                <Column dataField="branch" caption="Branch" dataType="string" />
                <Column dataField="id" caption="ID" dataType="number" />
                <Column dataField="secondaryID" caption="Secondary ID" dataType="string" />
                <Column dataField="forename" caption="Forename" dataType="string" />
                <Column dataField="surname" caption="Surname" dataType="string" />
                <Column dataField="hireCar" caption="Hire Car" cellRender={strikeCheckboxCell} />
                <Column dataField="hireOngoing" caption="Hire Ongoing" cellRender={strikeCheckboxCell} />
                <Column dataField="liabilityAdmitted" caption="Liability Admitted" dataType="string" />
                <Column dataField="billingStage" caption="Billing Stage" dataType="string" />
                <Column dataField="caseSource" caption="Case Source" dataType="string" />
                <Column dataField="userField1" caption="User Field 1" dataType="string" />
                <Column dataField="userField2" caption="User Field 2" dataType="string" />
                <Column dataField="incident" caption="Incident Date" dataType="date" />
                <Column dataField="open" caption="Open Date" dataType="date" />
                <Column dataField="processVersion" caption="Process Version " dataType="number" ><Format type="fixedPoint" precision={1}/></Column>
                <Column dataField="ufn" caption="UFN" dataType="string" />
                <Column dataField="lastUpdatedTime" caption="Updated" dataType="date" />
                <Column dataField="description" caption="Status" dataType="string" groupIndex={0} />
                <Column dataField="caseSubType" caption="Case Sub Type" dataType="string" groupIndex={1} />
                <Summary>
                    <GroupItem column="id" summaryType="count"  displayFormat={'{0}'}/>
                </Summary>
            </CustomGrid>
        </div>
    )
}
