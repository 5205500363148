import React from 'react'
import Modal from '../custom-modal'
import Button from '../../controls/button'
export default function CaseDuplicateAlert({hide, caseID}) {
  return (
    <div>
    <Modal title="Alert" hide={hide} className="modal-dialog  modal-lg " footer={
        <>
            <Button type='submit' size='medium' variant='contained' name='Ok' onClick={hide}></Button>
        </>
    }>
    <h6>Case {caseID} is already open.</h6>
    </Modal>
</div>
  )
}
