import React, { useState, useCallback, useEffect } from 'react';
import "./case-task.css"
import { CheckBox } from 'devextreme-react';
import EditTask from '../../modals/edit-task-modal';
import CustomGrid from '../../controls/grid';
import { useScreenSize } from '../../../hooks/useScreenSize';
import Modal from '../../modals/custom-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Button from '../../controls/button';
import { setToLS } from '../../../utils/helpers/storage';
import { getFromLS } from '../../../utils/helpers/storage';
import { tabSuffix, desktopSuffix } from '../../../utils/constants/storageKeys';
import { Mobile, Desktop, Tab } from '../../../utils/constants/devices';
import { CaseDetailTab } from '../../case-detail/case-detail-tab';
import { locale } from 'devextreme/localization';
import { TASKTYPE_COMPLETEDHISTORICALCASEDATES,TASKTYPE_CASECLOSURE } from '../../../utils/constants/task-type';
import { TASKSTATUS_COMPLETED } from '../../../utils/constants/task-status';
import { Column, Paging, Pager, FilterRow, HeaderFilter, Toolbar, Item, ColumnChooser, ColumnChooserSelection, StateStoring, SearchPanel, Scrolling, TotalItem, Summary, Sorting } from 'devextreme-react/data-grid';
const CaseTaskGrid = ({ hide, storeKey, homeView, taskData }) => {
  const [showEditTask, setShowEditTask] = useState(false);
  const [showViewTask, setShowViewTask] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [priority, setPriority] = useState(null);
  const [task, setTask] = useState(null);
  const [appointmentNotes, setAppointmentNotes] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [showCompletedTasks, setShowCompletedTasks] = useState(hide ? true : false);
  const [forceRender, setForceRender] = useState(false);
  const [homeViewColunm, setHomeView] = useState(false);
  const device = useScreenSize();
  const dataLength  = taskData.length;
  useEffect(() => {
    locale("en-GB");
    if (homeView) {
      setHomeView(true);
    }
  }, []);

  let storageKey;
  let mobile = false;

  if (device === Tab) {
    storageKey = storeKey + tabSuffix
    mobile = false;
  }
  else if (device === Desktop) {
    storageKey = storeKey + desktopSuffix
    mobile = false;
  }
  else if (device === Mobile) {
    mobile = true;
  }
  const onRowDblClick = (e) => {
    const rowData = e.data;
    // Perform any additional actions you want on row double-click
    setCaseId(rowData.CaseId);
    setUserName(rowData.User);
    setClientName(rowData.Client);
    setPriority(rowData.Priority);
    setTask(rowData.Task);
    setAppointmentNotes(rowData.AppointmentNotes);
    setDueDate(rowData.DueDate);
    setCompletedDate(rowData.CompletedDate);
    if (homeView) {
      CaseDetailTab(rowData.CaseId);
    } else {
      if (!mobile) {
        setShowEditTask(true);
      } else {
        setShowViewTask(true);
      }
      setShowCompletedTasks(false);
    }
  };
  const renderCell = (e) => {
    const currentDate = new Date(); // Get the current date
    const dueDate = new Date(e.data?.dueDate); // Get the due date from the cell value
    const teamID = e.data?.TeamID;
    const userID = e.data?.User;
    const caseID = e.data?.CaseId;
    const taskType = e.data?.type;
    const loggedInUserID = userID; // Replace with the actual logged-in user ID
    const loggedInUserTeams = [1, 2, 3]; // Replace with the list of team IDs from the logged-in user's profile
    const loggedInUserDelegateUserIDs = ["AMR", "789"]; // Replace with the list of delegated user IDs from the logged-in user's profile
    const gAllowCompleteCaseTasksFromDashboard = true; // Replace with the actual value
    const Supervisor = true;
    if (!dueDate || dueDate < currentDate) {
      e.rowElement.classList.add("red-cell");
    } else if (dueDate && dueDate < getFutureDate(7)) {
      e.rowElement.classList.add("yellow-cell");
    } else if (dueDate && dueDate > getFutureDate(7)) {
      e.rowElement.classList.add("green-cell");
    }
    if (e.data && e.data.status === TASKSTATUS_COMPLETED) {
      e.rowElement.classList.add('strike-through');
    } else {
      e.rowElement.classList.remove('strike-through');
    }
    // Check if it's a team task and the user is not a member of this team
    if (teamID !== null && teamID > 0) {
      if (!loggedInUserTeams.includes(teamID)) {
        e.rowElement.classList.add("strike-through");
        console.log("Task allocated to a team which you are not a member of");
      }
    }
    // Check if the task doesn't belong to the logged-in user, supervisor, or delegate
    else {
      const delegateForUser = loggedInUserDelegateUserIDs.includes(userID);
      if (!(loggedInUserID === userID || Supervisor || delegateForUser)) {
        console.log("Task not allocated to you and you are not a delegate of the task assignee");
      }
    }
    // Don't allow checking a task if it's linked to a case
    if (!gAllowCompleteCaseTasksFromDashboard && caseID !== null) {
      console.log("Task linked to a case and must be completed by opening the case");
    }
    // Don't allow changing check status of historical case date tasks
    if (taskType === TASKTYPE_COMPLETEDHISTORICALCASEDATES) {
      console.log("Can't change status of a historical case date task");
    }
    // Don't allow checking of a case closure task. These are auto-completed when the case is closed
    if (taskType === TASKTYPE_CASECLOSURE) {
      console.log("Status of Case Closure Task cannot be manually changed. It will be automatically completed when the case is closed");
    }

  };

  const toggleCompletedTasks = () => {
    setShowCompletedTasks((prevShowCompletedTasks) => !prevShowCompletedTasks);
  };

  const getFutureDate = (days) => {
    const currentDate = new Date();

    const futureDate = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000);
    return futureDate;
  };

  const handleCheckboxChange = (value, data) => {
  };

  const strikeCheckboxChange = (value, data) => {
    // Update the completed status of the task
    if (value.value === true) {
      data.CompletedDate = new Date();
    } else if (value.value === false) {
      data.CompletedDate = null;
    }
    // Force re-render of the grid to apply the changes
    setForceRender(!forceRender);
  };

  const deleteCheckboxCell = (props) => {
    const { data } = props;
    return (
      <>
        <div className="checkbox-cell">
          <CheckBox onValueChanged={(value) => handleCheckboxChange(value, data)} />
        </div>
      </>
    );
  };

  const strikeCheckboxCell = (props) => {
    const { data } = props;

    return (
      <div className="checkbox-cell">
        <CheckBox
          defaultValue={data.status === "C" ? true : false}
          disabled={data.TaskType === "CompletedHistoricalCaseDates" || data.TaskType === "CaseClosure"}
          onValueChanged={(value) => strikeCheckboxChange(value, data)}
        />
      </div>
    );
  };
  function DataRow(e) {
    return (
      <React.Fragment>
        <tr className="main-row">
          <td>{strikeCheckboxCell(e)}</td>
          <td>{e.data.User}</td>
          <td>{e.data.Client}</td>
          <td>{e.data.CaseId}</td>
          <td>{e.data.DueDate}</td>
          <td>{deleteCheckboxCell(e)}</td>
        </tr>
        <tr className="notes-row">
          <td colSpan="6"><div>{e.data.Task}</div></td>
        </tr>
      </React.Fragment>
    );
  }
  const data = [
    { label: 'Case ID:', value: caseId },
    { label: 'User:', value: userName },
    { label: 'Client:', value: clientName },
    { label: 'Priority:', value: priority },
    { label: 'Task:', value: task },
    { label: 'Appointment Notes:', value: appointmentNotes },
    { label: 'Due Date:', value: dueDate },
    { label: 'Completed Date:', value: completedDate ? completedDate.toString() : null },
  ];
  const filteredData = showCompletedTasks || homeView ? taskData : taskData.filter((taskData) => !taskData.completedDate);

  const loadState = useCallback(() => {
    return getFromLS(storageKey);
  }, []);

  const saveState = useCallback((state) => {
    setToLS(storageKey, state);
  }, []);

  return (
    <>
      {showEditTask && <EditTask Due_Date={dueDate} CaseId={caseId} Task={task} order={appointmentNotes} hide={() => setShowEditTask(false)} />}
      {showViewTask && <Modal icon={<FontAwesomeIcon icon={faEye} />} hide={() => setShowViewTask(false)} title="View Task" className="modal-dialog modal-dialog-centered" footer={
        <>
          <Button size='medium' variant='outlined' name='Close' onClick={() => setShowViewTask(false)}></Button>
          <Button type='submit' size='medium' variant='contained' name='Edit Task' onClick={() => { setShowEditTask(true); setShowViewTask(false); }}></Button>
        </>
      }>
        <div>
          {data.map((item, index) => (
            <div className="data-field" key={index}>
              <h6 className="heading">{item.label}</h6>
              <p className="paragraph">{item.value}</p>
            </div>
          ))}
        </div>
      </Modal>}
      <CustomGrid data={filteredData} onRowDblClick={onRowDblClick} dataRow={renderCell} mobileView={mobile ? DataRow : null} id="taskgridContainer" keyExpr="caseID" columnAutoWidth={true} wordWrapEnabled={true}>
        {!homeView ? <Toolbar>
          <Item location="before">
            <CheckBox defaultValue={false} checked={showCompletedTasks} onValueChanged={toggleCompletedTasks} text="Include Completed / Deleted Task" />
          </Item>
          <Item location="after">
            <h6>161005,Smith</h6>
          </Item>
        </Toolbar> : <SearchPanel visible={true} />}
        {dataLength  <= 100 ? (
          <>
            <Scrolling columnRenderingMode="virtual" />
           
          </>
        ) :      <Scrolling mode="infinite" />}
        <Sorting mode="none" />
        <Paging enabled={false} />
        <FilterRow visible={!mobile} />
        <HeaderFilter visible={true} />
        <StateStoring enabled={!mobile} type="custom" customLoad={loadState} customSave={saveState} />
        <Column caption="" cellRender={strikeCheckboxCell} />
        <Column dataField="linkedDescription" caption='Refference' dataType="string" visible={homeViewColunm} />
        <Column dataField="User" dataType="string" width={mobile ? 60 : null} visible={!homeViewColunm} />
        <Column dataField="Client" dataType="string" width={mobile ? 70 : null} visible={!homeViewColunm} />
        <Column dataField="caseID" width={mobile ? 70 : null} visible={mobile} />
        <Column dataField="priority" caption="Priority" dataType="string" width={mobile ? 72 : null} visible={!mobile && homeViewColunm} />
        <Column dataField="task" caption="Task" dataType="string" visible={!mobile}  />
        <Column dataField="AppointementNotes" dataType="string" visible={!mobile} />
        <Column dataField="dueDate" dataType="date" width={mobile ? 65 : null} />
        <Column dataField="completedDate" dataType="date" width={mobile ? 100 : null} visible={!mobile && !homeViewColunm} />
        <Column caption="Deleted" cellRender={deleteCheckboxCell} visible={!homeViewColunm} />
        <Summary>
          <TotalItem
            column="linkedDescription"
            summaryType="count"
            displayFormat={'Number of Tasks:{0} '}// You can customize the display format as needed
          />
        </Summary>
        <ColumnChooser enabled={true}>
          <ColumnChooserSelection />
        </ColumnChooser>
      </CustomGrid>
    </>
  );
}

export default CaseTaskGrid;
