import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import SystemSettings from './tool-submenu/tools-system-setting';
import CaseTool from './tool-submenu/tools-case-tools';
import DynamicData from './tool-submenu/tools-dynamic-data';
import ToolsFinancial from './tool-submenu/tools-financials';
import MiscUtilities from './tool-submenu/tools-misc-utilities';
import { anchor, lists, navigation, subNavigation } from '../../utils/styles/subMenu-style';

export default function SubNavigationTool() {
    const [showSubNavSystemSettings, setSubNavSystemSettings] = useState(false)
    const [showSubNavCaseTool, setSubNavCaseTool] = useState(false)
    const [showSubNavDynamicData, setSubNavDynamicData] = useState(false)
    const [showSubNavToolFinancial, setSubNavToolFinancial] = useState(false)
    const [showSubNavMiscUtilitties, setSubNavMiscUtilities] = useState(false)
    const [showSubNavTools, setSubNavTools] = useState(true)
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 1000);
        handleResize(); // Initial check
        if (!isSmallScreen) {
            setSubNavTools(true);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    return (
        <div>
            <SubNavWrapper>
                <SubNav>
                    {showSubNavTools ?
                        <ul className="navbar-nav me-auto">
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavSystemSettings(!showSubNavSystemSettings); if (isSmallScreen) { setSubNavTools(!showSubNavTools); } }}><i className="fa-solid fa-square-plus fa-xl"></i> System Settings</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavCaseTool(!showSubNavCaseTool); if (isSmallScreen) { setSubNavTools(!showSubNavTools); } }}><i className="fa-solid fa-envelope fa-xl"></i> Case Tools</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavDynamicData(!showSubNavDynamicData); if (isSmallScreen) { setSubNavTools(!showSubNavTools); } }}><i className="fa-solid fa-envelope-open-text fa-xl"></i> Dynamic Data</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavToolFinancial(!showSubNavToolFinancial); if (isSmallScreen) { setSubNavTools(!showSubNavTools); } }}><i className="fa-solid fa-envelope-circle-check fa-xl"></i> Financial</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavMiscUtilities(!showSubNavMiscUtilitties); if (isSmallScreen) { setSubNavTools(!showSubNavTools); } }}><i className="fa-solid fa-envelope-circle-check fa-xl"></i> Misc Utilities</Anchor></List>
                        </ul>
                        : null
                    }
                    {showSubNavSystemSettings ? <SystemSettings></SystemSettings> : null}
                    {showSubNavCaseTool ? <CaseTool></CaseTool> : null}
                    {showSubNavDynamicData ? <DynamicData></DynamicData> : null}
                    {showSubNavToolFinancial ? <ToolsFinancial></ToolsFinancial> : null}
                    {showSubNavMiscUtilitties ? <MiscUtilities></MiscUtilities> : null}
                </SubNav>
            </SubNavWrapper>
        </div>
    )
}
const SubNavWrapper = styled.div`
        ${navigation}
    `;
const SubNav = styled.div`
    ${subNavigation}
    `;
const List = styled.li`
    ${lists}
    `;
const Anchor = styled.a`
     ${anchor}
    `;

