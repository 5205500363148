import React from 'react'
import CaseDetailLayout from '../../components/shared/case-detail-layout'
import styled from 'styled-components'
import AdditionalInformation from '../../components/dynamic-entity/additional-information'
import EnittyTypeT from '../../components/dynamic-entity/entity-type-t/entity-type-t-form-a/entity-type-t'
export default function EntityTypetFormAView({entityType ,entityName, detailIndex, caseID, orgID, entityID,casePersonID ,selectFromList}) {
  return (
    <div>
      <div className="row">
        <div className='col-lg-6 col-md-6 col-12'>
          <Container>
           <EnittyTypeT entityName={entityName} selectFromList={selectFromList}></EnittyTypeT>
          </Container>
        </div>
        <div className='col-lg-6 col-md-6 col-12'>
          <Container>
            <AdditionalInformation entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID}></AdditionalInformation>
          </Container>
        </div>
      </div>

    </div>
  )
}
const Container = styled.div`
margin: 10px;
border: 1px solid #ddd;
border-radius: 5px;
padding: 10px 10px 10px 10px;

height:600px;
overflow-y: auto;
::-webkit-scrollbar {
width: 8px;
background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
background-color: #888;
border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
background-color: #555;
}
`;