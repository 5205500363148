const historyData = [
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Nothingham",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00"
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00"
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00"
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00"
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00"
    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true

    },
    {
        User: 'AMR',
        CaseId: '16102',
        Branch: "Derby",
        Date: "6/16/2023",
        Time: "4:27",
        FeeEarnerID: "R Razaq",
        Narrative: "Case Status changed from FraudStatedEnd to PENDING_CLOSE",
        SubFolder: "",
        Quantity: "0:00",
        NoOfUnits: "0",
        Cost: "0.00",
        File: "",
        Minutes: "0:00",
        ElapsedMinutes: "0:00",
        LinkedDocument: true
    }
]
export default historyData