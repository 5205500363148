export const setToLS = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));

};

export const deleteFromLS = (key) => {
  window.localStorage.removeItem(key);
};

export const getFromLS = (key) => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};
export const setToSS = (key, value) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));

};
export const getFromSS = (key) => {
  const value = window.sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};