import TextField from "@mui/material/TextField";
import React from 'react'

export default function TextArea(props) {
  return (
    <div>
       {props.disabled ?
      <TextField
     
        fullWidth
        id={props.id}
        label={props.label}
        multiline
        rows={props.row}
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        disabled
      />
      :
      <TextField
     
        fullWidth
        id={props.id}
        label={props.label}
        multiline
        rows={props.row}
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        
      />}
    </div>
  )
}
