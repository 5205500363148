import React from 'react'
import CustomGrid from '../../controls/grid';
import { Column, Paging, ColumnChooser, ColumnChooserSelection } from 'devextreme-react/data-grid';
import "./cases.css";
export default function CasesGrid({ searchData }) {
    const mergeForenameAndSurname = (rowData) => {
        return rowData.surname + ' ' + rowData.forename;
    };
    return (
        <div>
            <CustomGrid data={searchData} id="caseSearchGridContainer" keyExpr="id" columnAutoWidth={true} >
                <Paging enabled={false} />
                <Column dataField="id" caption="Case ID" dataType="number" />
                <Column dataField="secondaryID" caption="Secondary ID" dataType="string" />
                <Column caption="Client Name" calculateCellValue={mergeForenameAndSurname} dataType="string" />
                <Column dataField="status" caption="Status" dataType="string" />
                <Column dataField="caseType" caption="Case Type" dataType="string" />
                <Column dataField="caseSubType" caption="Case Sub Type" dataType="string" />
                <Column dataField="feeEarnerID" caption="Fee Earner" dataType="string" />
                <Column dataField="supervisorID" caption="Supervisor ID" dataType="string" />
                <Column dataField="open" caption="Open Date" dataType="date" />
                <Column dataField="incident" caption="Incident Date" dataType="date" />
                <Column dataField="close" caption="Close Date" dataType="date" />
                <Column dataField="lastUpdatedTime" caption="Last Update Time" dataType="string" />
                <Column dataField="" caption="Client Insurance" dataType="string" visible={false} />
                <Column dataField="" caption="Client Insurance Ref" dataType="string" visible={false} />
                <Column dataField="" caption="Other Party" dataType="string" visible={false} />
                <Column dataField="" caption="Other Party Ref" dataType="string" visible={false} />
                <Column dataField="" caption="Key Value 1" dataType="string" visible={false} />
                <Column dataField="" caption="Key Value 2" dataType="string" visible={false} />
                <ColumnChooser enabled={true}>
                    <ColumnChooserSelection />
                </ColumnChooser>
            </CustomGrid>
        </div>
    )
}
