import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
export default function Datepicker(props) {
    return (
        <div>
        
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    id={props.id}
                    label={props.label}
                    value={props.value} 
                    slotProps={{ textField: { variant: 'standard',fullWidth:true,size:"small"} }}  
                />
            </LocalizationProvider>
        </div>
    )
}
