import React , {useState,useEffect} from 'react'
import TextInput from '../../components/controls/textinput'
import Datepicker from '../../components/controls/datepicker'
import Dropdown from '../../components/controls/drop-down'
import styled from 'styled-components'
import { CaseDetailTab } from '../../components/case-detail/case-detail-tab'
import Button from '../../components/controls/button'
import { caseSearch } from '../../services/case-service'
export default function CaseSearch({onSelectedViewChange, caseSearchData }) {
    const [caseID, setCaseID] = useState("");
    const [secondaryID, setSecondaryID] = useState('');
  const [claimsPortalID, setClaimsPortalID] = useState('');
  const [uFN, setUFN] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [viewTaskValue, setViewTaskValue] = useState("");
  const [startDate, setStartDate] = useState(null); // Use null for Datepicker initial state
  const [endDate, setEndDate] = useState(null); // Use null for Datepicker initial state
  const [otherPartyName, setOtherPartyName] = useState('');
  const [otherPartyRef, setOtherPartyRef] = useState('');
  const [keyField1, setKeyField1] = useState('');
  const [keyValue1, setKeyValue1] = useState('');
  const [keyField2, setKeyField2] = useState('');
  const [keyValue2, setKeyValue2] = useState('');
const viewtask = [
    {
        id : 1,
        name : "Your Tasks Overdue / due soon"

    },
    {
        id : 2,
        name : "Your Tasks due later"

    },
    {
        id : 3,
        name : "Limitation Reminders"

    }
    ,
    {
        id : 4,
        name : "Claims Portal Date Limits"

    }
    ,
    {
        id : 5,
        name : "Notifications"

    }

]
useEffect(() => {
    setViewTaskValue(viewtask[0].name);
    onSelectedViewChange(viewtask[0].name);;
    
  }, []);
const handleInputChange = (event, setStateFunction) => {
    const { value } = event.target;
    setStateFunction(value);
  };
      const onSearchClick = () => {
     
    if(caseID)
    {
        CallCaseDetailApi();
    }
    else{
        CallCaseSearchApi();
    }
      };
      const CallCaseDetailApi = async () => {
        CaseDetailTab(caseID);
      };
      const CallCaseSearchApi = async () => {
        try {
            const casesearch = await caseSearch(clientName);
            caseSearchData(casesearch.data.caseInfo);
        
         
        }
        catch (error) {
             // Log any errors
        }
      };
      const handleViewTaskChange = (event) => {
        const { value } = event.target;
        // Call the callback function to update the selected view
        onSelectedViewChange(value);
        setViewTaskValue(value);
      };
    return (
        <>
            <DivWrapper>
            <h5>Task & Calendars</h5>
                <hr></hr>
            <div className='row g-3'>
                <div className='col-lg-12 col-md-12 col-12'>
                    <Dropdown label="View Task" id="viewTask" value={viewTaskValue} options={viewtask} onChange={handleViewTaskChange}></Dropdown>
                </div>
                <div className='col-lg-12 col-md-6 col-12'>
                    <Dropdown label="View Calendar" id="viewCalendar" ></Dropdown>
                    </div>
                </div>
            </DivWrapper>
            <DivWrapper >
                <h5>Search</h5>
                <hr></hr>
                <div className='row g-3'>
                    <div className='col-lg-4 col-md-6 col-6 '>
                        <TextInput label="Case ID" id="caseID" variant="standard"  onChange={(event) => handleInputChange(event, setCaseID)}></TextInput>
                    </div>
                    <div className='col-lg-4 col-md-6 col-6'>
                        <TextInput label="Secondary ID" id="secondaryID" variant="standard" onChange={(event) => handleInputChange(event, setSecondaryID)}></TextInput>
                    </div>
                    <div className='col-lg-4 col-md-6 col-6'>
                        <TextInput label="Claims Portal ID" id="claimsPortalID" variant="standard" onChange={(event) => handleInputChange(event, setClaimsPortalID)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="UFN" id="uFN" variant="standard" onChange={(event) => handleInputChange(event, setUFN)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Client Name" id="clientName" variant="standard" onChange={(event) => handleInputChange(event, setClientName)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Client Address" id="clientAddress" variant="standard" onChange={(event) => handleInputChange(event, setClientAddress)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Phone Number" id="phoneNumber" variant="standard" onChange={(event) => handleInputChange(event, setPhoneNumber)}></TextInput>
                    </div>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <TextInput label="Email Address" id="emailAddress" variant="standard"onChange={(event) => handleInputChange(event, setEmailAddress)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Datepicker label="Start Date" id="startDate"></Datepicker>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Datepicker label="End Date" id="endDate"></Datepicker>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Other Party Name" id="otherPartyName" variant="standard" onChange={(event) => handleInputChange(event, setOtherPartyName)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Other Party Ref" id="otherPartyRef" variant="standard" onChange={(event) => handleInputChange(event, setOtherPartyRef)}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Key Field" id="keyField1" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Key Value" id="keyValue1" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Key Field" id="keyField2" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6 '>
                        <Dropdown label="Key Value" id="keyValue2" ></Dropdown>
                    </div>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <Button size='medium' variant='contained' name='Search' fullWidth={true}  onClick={onSearchClick}></Button>
                    </div>
                </div>
            </DivWrapper>
        </>
    )
}
const DivWrapper = styled.div`
  margin: 0 0 10px 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
