import React from 'react'
import styled from 'styled-components';
export default function MainCaseDetailNavigataion({ homeClick }) {
  return (
    <div>
      <SubNavWrapper className="navbar navbar-expand-lg">
        <UnorderList >
          <List className="nav-item"><Anchor className="nav-link" href="#" onClick={homeClick}>Home</Anchor></List>
          <List className="nav-item"><Anchor className="nav-link" href="#">Option</Anchor></List>
        </UnorderList>
      </SubNavWrapper>
    </div>
  )
}
const SubNavWrapper = styled.div`
 background: ${({ theme }) => theme.palette.nav.main};
  height:44px;
  width:100%;
  
`;
const Anchor = styled.a`
 
 font-size:16px;
 border-radius:4px;
 &:hover {
   background-color:${({ theme }) => theme.palette.nav.onHoverBackground};
   color: ${({ theme }) => theme.palette.nav.onHoverTextIcon};
 
  }
   color: ${({ theme }) => theme.palette.nav.textIcon};
`;
const List = styled.li`

padding-left:2rem;

`;
const UnorderList = styled.ul`
display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style-type: none;


`;