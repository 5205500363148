import React from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styled from "styled-components";
import {
  form,
  heading,
  icon,
  link,
} from "../../../utils/styles/shared-login-styles";

import { PrimaryButton } from "../../../utils/styles/button-styles";
const LoginForm = ({
  userId,
  password,
  OnLogin,
  OnForgotPasswordClick,
  onUserIDChange,
  onPasswordChange,
  onCaptchaChange,
  showError,
  showCaptcha,
}) => {
  const [showEye, setShowEye] = React.useState(false);

  const toggleEye = () => {
    setShowEye(!showEye);
  };
  return (
    <LoginFormContainer>
      <div className="col-sm-12">
        <Heading>Login</Heading>
        <hr />
        <form onSubmit={OnLogin}>
          {showError && (
            <div className="alert alert-danger">
              You have entered an invalid username or password
            </div>
          )}
          <div className="form-group">
            <label htmlFor="formGroupInput"></label>
            <div className="input-group mb-4">
              <TextField
                id="outlined-basic formGroupInput"
                className="form-control"
                label="User ID"
                variant="outlined"
                value={userId}
                onChange={onUserIDChange}
                required
              />
              <Icon>
                <i className="fa-solid fa-id-card"></i>
              </Icon>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="InputPassword1"></label>
            <div className="input-group">
              <TextField
                id="outlined-password-input"
                label="Password"
                type={showEye ? "text" : "password"}
                autoComplete="current-password"
                className="form-control"
                value={password}
                onChange={onPasswordChange}
                required
              />
              <Icon>
                <i
                  onClick={toggleEye}
                  className={`fa-solid ${showEye ? "fa-eye-slash" : "fa-eye"}`}
                ></i>
              </Icon>
            </div>
          </div>
          {showCaptcha && (
            <ReCAPTCHA
              className="mt-2"
              sitekey="6LeepqIlAAAAAOA7d-nMvL-5kJkchCN4Tks4Mvdy"
              onChange={onCaptchaChange}
            />
          )}
          <StyledButton
            variant="contained"
            className="w-100 mt-4 mb-4"
            type="submit"
          >
            Log in
          </StyledButton>
          <div onClick={OnForgotPasswordClick}>
            <ForgotPasswordLink>Forgot password?</ForgotPasswordLink>
          </div>
        </form>
      </div>
    </LoginFormContainer>
  );
};

export default LoginForm;

const LoginFormContainer = styled.div.attrs({
  className: "container",
})`
  ${form}
`;

const Heading = styled.h1`
  ${heading}
`;

const Icon = styled.span.attrs({
  className: "input-group-text",
})`
  ${icon}
`;

const ForgotPasswordLink = styled.p.attrs({
  className: "text-right",
})`
  ${link}
`;

const StyledButton = styled(Button)`
  ${PrimaryButton}
`;
