const CaseSubTypePersonalInjuries = [
    {
        id : 1,
        name : "RTA"

    },
    {
        id : 2,
        name : "Pedistrian Accident"

    },
    {
        id : 3,
        name : "Public Liability"

    },
    {
        id : 4,
        name : "Employer's Liability (Accident)"

    },
    {
        id : 5,
        name : "Employer's Liability (Disease)"

    },
    {
        id : 6,
        name : "Holiday (Accident)"

    },
    {
        id : 7,
        name : "Holiday (Illness)"

    },
    {
        id : 8,
        name : "Medical Negligence"

    },
    {
        id : 9,
        name : "Criminal Injuries"

    },
    {
        id : 10,
        name : "Other"

    },
   
]
export default CaseSubTypePersonalInjuries;