import React, { useEffect, useState } from 'react';
import EntityTypeTFormBView from '../../views/case-detail/entity-type-t-form-b-view';
import EntityTypeOFormView from '../../views/case-detail/entity-type-o-form-view';
import EntityTypetFormAView from '../../views/case-detail/entity-type-t-form-a-view';
import EntityTypeDFormView from '../../views/case-detail/entity-type-d-form-view';
import { DYNAMIC_ENTITYORG, DYNAMIC_ENTITYDETAILS, DYNAMIC_ENTITYPERSON } from '../../utils/constants/entity-types';
const DynamicRoute = ({ entityType, entityName, detailIndex, caseID, orgID, entityID, selectFromList, casePersonID }, data) => {
  // Local state to trigger re-render when entityName changes
  // Conditionally render different components based on entityType
  let componentToRender = null;
  switch (entityType) {
    case DYNAMIC_ENTITYORG:
   componentToRender = <EntityTypeOFormView key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} ></EntityTypeOFormView>;
      // componentToRender = <MainComponent key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID}></MainComponent>;
      break;
    case DYNAMIC_ENTITYPERSON:
      if (selectFromList) {
        componentToRender = <EntityTypetFormAView key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} selectFromList={selectFromList} ></EntityTypetFormAView>;
      }
      else {
        componentToRender = <EntityTypeTFormBView key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} />;
      }
      break;
    case DYNAMIC_ENTITYDETAILS:
   componentToRender = <EntityTypeDFormView key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} />;
   
      break;
    default:
      // Render a default component if none of the above cases match
      break;
  }
  return (
    <div>
      {componentToRender}
    </div>
  );
};

export default DynamicRoute;
