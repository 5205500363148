

import axios from "axios";
import { getFromLS } from "../utils/helpers/storage";
import { useSubDomain } from "../hooks/useSubDomain";

export const httpClient = axios.create({
  baseURL: "https://api.cmanager.co.uk/api/",
});

httpClient.interceptors.request.use(
  function (config) {
    const { domain } = useSubDomain();
    // Do something before request is sent
    config.headers["client"] = domain;
    var user = getFromLS("User");
    const token = user?.token;
    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
    
  }
);

httpClient.interceptors.response.use(
  function (response) {
   
    return response;
  },
  function (error) {
 
    return Promise.reject(error);
  }
);
