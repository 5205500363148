import  { css } from "styled-components";

export const form = ({ theme, myProp }) => css`
  background: ${theme.palette.secondary.main};
  margin-right: 11%;
  padding: 45px;
  border-radius: 1%;
  width: 32%;
  text-align: left;
  -webkit-box-shadow: 3px 6px 34px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 6px 34px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 6px 34px -12px rgba(0, 0, 0, 0.75);
  margin-bottom: 8%;
  @media (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`;

export const heading = ({ theme, myProp }) => css`
  text-align: center;
  font-family: $font-Name;
  font-weight: 700;
  color: ${theme.palette.primary.main};
`;

export const icon = ({ theme, myProp }) => css`
  background: linear-gradient(
    135deg,
    ${theme.palette.primary.gradient1},
    ${theme.palette.primary.gradient2}
  );
  border: none;
  left: -10px;
  position: relative;
  color: ${theme.palette.primary.contrastTextColour};
`;

export const link = ({ theme, myProp }) => css`
  color: ${theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;
