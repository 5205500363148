import React from 'react'
import CaseTaskGrid from '../../components/data-grid/case-task-grid/task-grid'
import { useAuth } from '../../hooks/useAuth'
import { mainCaseTask } from '../../utils/constants/application-areas';
export default function CaseTaskView() {
    const { user } = useAuth();
    const key = user.userID + mainCaseTask;
    return (
            <CaseTaskGrid storeKey={key}></CaseTaskGrid>
    )
}
