import React, { useState, createContext, useContext } from "react";
import SideBar from "./sideBar";

const SidebarContext = createContext({});


export const MyProSidebarProvider = ({ children, caseType, caseSubType }) => {
    const sidebarValue = {
        caseType,
        caseSubType,
        // Add other properties or functions you want to make available here
    };
    return (

        <SidebarContext.Provider  value={sidebarValue}>
            <div>
                <SideBar >
                {children}
                </SideBar>
            </div>
        </SidebarContext.Provider>

    );
};

export const useSidebarContext = () => useContext(SidebarContext);
