import React from 'react'
import { caseDetail } from '../../services/case-service';
import { setToLS,getFromLS } from '../../utils/helpers/storage';

export async function  CaseDetailTab  (caseID) {
    const isOpened = getFromLS(`case_${caseID}`);

    if (isOpened) {
        console.log(`Case ${caseID} is already open.`);
        return;
    }
    else {

        try {
            const response = await caseDetail(caseID, true);
            
            if (response.status === 200) { // Check if the response status code is 200 (OK)
                const caseId = response.data.cases[0].id;
                const caseSubType = response.data.cases[0].caseSubType;
                const caseType = response.data.cases[0].caseType;
                // Construct the URL with the caseID parameter
                const newTabUrl = `/casedetail/${caseId}?caseType=${caseType}&caseSubType=${caseSubType}`;
                window.open(newTabUrl, '_blank');
                // Set the value in local storage after a successful response
                setToLS(`case_${caseID}`, 'true');
              } else {
                console.log(`Error: Case ${caseID} could not be opened.`);
                // Handle the error as needed
              }
        }
        catch (error) {
           // Log any errors
        }
       
    }

}
