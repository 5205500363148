import React, { useState } from "react";
import LoginForm from "../../components/login/login-form/login-form";
import LoginFooter from "../../components/login/login-footer/login-footer";
import ForgotPasword from "../../components/login/forgot-password/forgot-password";
import styled from "styled-components";
import useImage from "../../hooks/useImage";
import { authenticateUser } from "../../services/user-service";
import Loader from "../../components/shared/loader";
import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router";

function LoginView({ content }) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [password, setPassword] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [incorrectAttempts, setIncorrectAttempts] = React.useState(0);
  const [showError, setShowError] = React.useState(false);
  const [showCaptcha, setShowCaptcha] = React.useState(false);
  const [recaptchavalue, setCaptchaValue] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const { onLogin, user } = useAuth();
  const image = useImage("logos/" + content.logo);
  // if the user is logged in already, navigate them to home.
  if (user) {
    return <Navigate to="/home"></Navigate>;
  }
  const toggleForgetPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };
  const handleForgotPassword = async (event) => {
    event.preventDefault();
    // to do, call forgot password
  };
  const handleLogin = async (event) => {
    event.preventDefault();
    setShowLoading(true);
    try {
      var response = await authenticateUser(
        userId,
        password,
        "C",
        "192.168.0.1"
      );
      // Log the user in
      onLogin(response.data);
    } catch (error) {
      
      // Increment the number of incorrect attempts
      setIncorrectAttempts(incorrectAttempts + 1);
      setShowError(true);
      // Show the captcha if the number of incorrect attempts is three or more
      if (incorrectAttempts >= 2) {
        setShowCaptcha(true);
      }
    } finally {
      setShowLoading(false);
    }
  };
  const onUserIDChange = (event) => {
    setUserId(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onCaptchaChange = (value) => {
    setCaptchaValue(true);
  };
  return (
    <LoginWrapper>
      <Loader showLoader={showLoading} />
      <LoginContent>
        <ImgLogo src={image.image} alt="logo"></ImgLogo>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-6">
              <Header>{content.welcomeText}</Header>
            </div>
            {showForgotPassword ? (
              <ForgotPasword
                onBackToLogin={toggleForgetPassword}
                onUserIDChange={onUserIDChange}
                userId={userId}
                onForgetPassword={handleForgotPassword}
              ></ForgotPasword>
            ) : (
              <LoginForm
                userId={userId}
                password={password}
                OnLogin={handleLogin}
                onUserIDChange={onUserIDChange}
                onPasswordChange={onPasswordChange}
                showError={showError}
                showCaptcha={showCaptcha}
                onCaptchaChange={onCaptchaChange}
                OnForgotPasswordClick={toggleForgetPassword}
              />
            )}
          </div>
        </div>
      </LoginContent>
      <LoginFooter content={content} />
    </LoginWrapper>
  );
}

export default LoginView;

const LoginWrapper = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
`;

const LoginContent = styled.div`
  min-height: calc(100vh - 291px);
`;

const ImgLogo = styled.img`
  padding-left: 5%;
  margin: 1% auto;

  @media (max-width: 1000px) {
    display: block;
    padding: 5%;
  }
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.palette.primary.contrastTextColour};
  float: left;
  font-size: 3.5rem;
  line-height: 1.5;
  font-weight: 900;
  text-align: left;
  margin: 8% 8%;
  @media (max-width: 1000px) {
    display: none;
  }
`;
