import React ,{useEffect,useState} from 'react'
import { Column ,Scrolling,Paging} from 'devextreme-react/data-grid';
import CustomGrid from '../../controls/grid';
import { recentList } from '../../../services/case-service';
import { useAuth } from '../../../hooks/useAuth';
import { locale} from 'devextreme/localization';
import "./recent-list.css";
export default function CaseRecentGrid() {
  const [recentListData, setRecentListData] = useState([]);
  const { user } = useAuth();
    useEffect(() => {
      locale('en-GB');
        CallApi();
    }, []);
    const CallApi = async () => {
        try {
            const recentlist = await recentList(user.userID);
            setRecentListData(recentlist.data.recentCase)
        }
        catch (error) {
           // Log any errors
        }
    };
  return (
    <div>
       <CustomGrid data={recentListData}  id="caseRecentGridContainer" keyExpr="caseID" columnAutoWidth={true} wordWrapEnabled={true}>
        <Paging enabled={false} />
                <Column dataField="caseID" caption="Case" dataType="number" />
                <Column dataField="clientName" caption="Name" dataType="string" />
                <Column dataField="timeAccessed" caption='Time Accessed' dataType="datetime" />
            </CustomGrid>
    </div>
  )
}
