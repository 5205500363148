import React, { useState, useRef, Children } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import CustomGrid from './grid';
import {
  Selection, Paging, FilterRow, Scrolling, Pager, ColumnChooser, ColumnChooserSelection, SearchPanel 
} from 'devextreme-react/data-grid';
const allowedPageSizes = [10, 25, 50, 100];
const ownerLabel = { 'aria-label': 'Owner' };
function LookUpList({ data, value, children, footer }) {
  const [gridBoxValue, setGridBoxValue] = useState([3]);
  const syncDataGridSelection = (e) => {
    setGridBoxValue(e.value || []);
  };
  const dataGridOnSelectionChanged = (e) => {
    setGridBoxValue(e.selectedRowKeys.length ? e.selectedRowKeys : []);

  };
  const handleRowDoubleClick = (rowData) => {
    // Call the callback function with the selected column data
console.log(rowData.data);

  };
  function dataGridRender() {
    return (
      <>
        <CustomGrid
          data={data}
          onSelectionChanged={dataGridOnSelectionChanged}
          selectedRowKey={gridBoxValue}
          height={380}
          id="gridContainer"
          onRowDblClick={handleRowDoubleClick}
        >
          <Selection mode="single" />
          <Scrolling mode="standard" />
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} />
          <FilterRow visible={true} />
          <SearchPanel visible={true} />
          {children}
          <ColumnChooser enabled={true}>
            <ColumnChooserSelection
            />
          </ColumnChooser>
        </CustomGrid>
 
          {footer}

      </>
    );
  }

  return (
    <>
      <DropDownBox
        value={gridBoxValue}
        displayExpr="name" // Specify the property to display
        deferRendering={false}
        inputAttr={ownerLabel}
        placeholder="Select a value..."
        showClearButton={true}
        dataSource={data}
        onValueChanged={syncDataGridSelection}
        contentRender={dataGridRender}
        dropDownOptions={{ width: 1000, height: 600 }}
      />
    </>
  );
}

export default LookUpList;
