const CaseSubTypeCrime = [
    {
        id : 1,
        name : "Crime"

    },
    {
        id : 2,
        name : "Extradition"

    },
   
]
export default CaseSubTypeCrime;