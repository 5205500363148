import  { css } from "styled-components";


export const anchor = ({ theme, myProp }) => css`
    color: ${({ theme }) => theme.palette.subMenu.textIcon};
   padding-left:10px;
   border-radius:10px;
   &:hover {
   background-color:${({ theme }) => theme.palette.subMenu.onHoverBackground};
   color: ${({ theme }) => theme.palette.subMenu.onHoverTextIcon};
   transform: scale(1.02);
  }
   @media (max-width: 1000px) {
    color: ${({ theme }) => theme.palette.subMenu.textIcon};
      }
  
`;
export const lists = ({ theme, myProp }) => css`
   padding-left:4rem;
@media (max-width: 1000px) {
    padding-bottom:16px;
    padding-left:0px;
  }
`;
export const navigation =({theme , myProp})=>css`
    position :absolute;
   top: 100%;
   right: 0%;
   width: 100%;
   margin:1px 10px 0 10px;
   @media (max-width: 1000px) {
    position :relative;
    margin:0 4px 0 0;

  }
`;
export const  subNavigation =({theme , myProp})=>css`
   border-radius:16px;
      background :  ${({ theme }) => theme.palette.subMenu.main};
      padding : 10px 10px 10px 0px;
   margin-left:20px;
   @media (max-width: 1000px) {
    margin-left:0;
        background :  ${({ theme }) => theme.palette.subMenu.main};
      }
`;
