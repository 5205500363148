import React from "react";
import styled from "styled-components";

var date = new Date();
var currentYear = date.getFullYear();
function LoginFooter({ content }) {
  return (
    <LoginFooterWrapper>
      <footer className="text-center text-lg-start">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 className="text-uppercase">
                <i className="fas fa-gem me-3"></i>
                {content.name}
              </h5>
              <p>{content.termsText}</p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              {content.displayEmailInHeader && (
                <p>
                  <i className="fas fa-envelope me-3"></i>
                  {content.email}
                </p>
              )}
              {content.displayContactNumberInHeader && (
                <p>
                  <i className="fas fa-phone me-3"></i>
                  {content.contactNumber}
                </p>
              )}
              {content.displayURLInHeader && (
                <p>
                  <i className="fas fa-globe me-3"></i>
                  {content.url}
                </p>
              )}
            </div>
          </div>
        </div>
        <Copyright>
          © {currentYear} Copyright:
          <a
            className="text-text-reset fw-bold ms-2"
            href="http://www.derbydatabases.com/"
          >
            Derby Databases
          </a>
        </Copyright>
      </footer>
    </LoginFooterWrapper>
  );
}
export default LoginFooter;

const LoginFooterWrapper = styled.div`
  height: 286px;
  background: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastTextColour};
`;
const Copyright = styled.div.attrs({
  className: "text-center p-3",
})`
  background-color: rgba(0, 0, 0, 0.2);
`;
