import * as React from 'react';
import MaterialIconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
export default function IconButtons({ color, label, size, children, onClick }) {
    return (
        <Stack direction="row" spacing={1}>
            <MaterialIconButton color={color} aria-label={label} size={size} onClick={onClick}>
                {children}
            </MaterialIconButton>
        </Stack>
    );
}
