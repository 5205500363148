import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Button from '../controls/button'
import Modal from './custom-modal'
import TaskForm from './task-form-modal'
export default function Addtask(props) {
    return (
        <div>
            <Modal icon={<FontAwesomeIcon icon={faPlusCircle} />} hide={props.hide} title="Add Task" className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={props.hide}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Add Task'></Button>
                </>
            }>
            <TaskForm CaseId={props.caseID}></TaskForm>
            </Modal>
        </div>
    )
}
