import React from 'react'
import CaseDatesGrid from '../../components/data-grid/case-date-grid/case-dates-grid'
import { useAuth } from '../../hooks/useAuth'
import { mainCaseDate } from '../../utils/constants/application-areas';

export default function CaseDateView() {
    const { user } = useAuth();
    const key = user.userID + mainCaseDate;
    return (
            <CaseDatesGrid storeKey={key}></CaseDatesGrid>
    )
}
