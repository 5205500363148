import React, { useState } from 'react'
import styled from 'styled-components';
import AddCases from '../modals/add-case-modal';
import Addtask from '../modals/add-task-modal';
import { anchor, navigation, lists, subNavigation } from '../../utils/styles/subMenu-style';

export default function SubNavigationHome() {
  const [showAddTask, setShowAddTask] = useState(false)
  const [showAddCase, setShowAddCase] = useState(false)
  return (
    <div>
      <SubNavWrapper>
        <SubNav>
          <ul className="navbar-nav me-auto">
            <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-users fa-lg"></i> Switch Users</Anchor></List>
            <List><Anchor className="nav-link" href="#" onClick={() => setShowAddTask(!showAddTask)}><i className="fa-solid fa-square-plus fa-xl"></i> Add New Task</Anchor></List>
            <List><Anchor className="nav-link" href="#" onClick={() => setShowAddCase(!showAddCase)}><i className="fa-solid fa-square-plus fa-xl"></i> Add New Case</Anchor></List>
            <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-envelope fa-xl"></i> Proccess Incoming Mail</Anchor></List>
            <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-envelope-open-text fa-xl"></i> Aprove Outgoing Mail</Anchor></List>
            <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-envelope-circle-check fa-xl"></i> Proccess Approved Mail</Anchor></List>
          </ul>
        </SubNav>
      </SubNavWrapper>
      {showAddTask ? <Addtask hide={() => setShowAddTask(false)}></Addtask> : null}
      {showAddCase ? <AddCases hide={() => setShowAddCase(false)}></AddCases> : null}
    </div>
  )
}
const SubNavWrapper = styled.div`
      ${navigation}
`;
const SubNav = styled.div`
 ${subNavigation}
`;
const List = styled.li`
${lists}
padding-left:3rem;
`;
const Anchor = styled.a`
    ${anchor}

`;
