import React, { useState } from 'react'
import Modal from './custom-modal'
import TextInput from '../controls/textinput'
import CheckBox from '../controls/checkbox'
import Dropdown from '../controls/drop-down'
import CaseTaskGrid from '../data-grid/case-task-grid/task-grid'
import Button from '../controls/button'
import TextArea from '../controls/textarea'
import CaseHistoryGrid from '../data-grid/case-history-data-grid/case-history-grid'
import styled from 'styled-components'
import Addtask from './add-task-modal'
import { useAuth } from '../../hooks/useAuth'
export default function CrudModal({ hide, name, title, caseID, timeStamp, numberOfUnits, quantity, cost }) {
    const { user } = useAuth();
    const [ showAddTask, setAddTask ] = useState(false)
    const key = user.userID + "FrmEditScreenHistory";
    return (
        <div>
       
            <Modal className="modal-dialog modal-xl" hide={hide} title={title} footer={
                <>

                    <Button size='medium' variant='outlined' name='Close' onClick={hide} ></Button>
                    <Button type='submit' size='medium' variant='contained' name={name} ></Button>

                    <div className='container'>
                        {name === "Delete" ?
                        <span className="name">hello</span>
                            : null}
                            <Button type='submit' size='medium' variant='contained' name="Add Case Task" onClick={() => setAddTask(true)}></Button>
                        <NavWrapper >
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Task</button>
                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">History</button>

                            </div>
                        </NavWrapper>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"> <CaseTaskGrid hide="true"></CaseTaskGrid></div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><CaseHistoryGrid hide="true" storeKey={key}></CaseHistoryGrid></div>
                        </div>
                    </div>
                </>
            }>
                <div className='container'>
                    <div className='row g-3'>
                        <div className='col-lg-4 col-4'>
                            <TextInput label="Case ID" variant="standard" disabled="true" value={caseID}></TextInput>
                        </div>
                        <div className='col-lg-4 col-4'>
                            <Dropdown label="User" value={caseID} ></Dropdown>

                        </div>
                        <div className='col-lg-4 col-4'>
                            <TextInput label="Time Stamp" variant="standard" value={timeStamp}></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Start Time" variant="standard" disabled="true"></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="End Time" variant="standard" disabled="true"></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Number of Units" variant="standard" disabled="true" value={numberOfUnits}></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <CheckBox label="Fixed Fee Entry"></CheckBox>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Time" variant="standard" ></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Quantity" variant="standard" value={quantity}></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <Dropdown label="Type Of Work"  ></Dropdown>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Cost" variant="standard" disabled="true" value={cost}></TextInput>
                        </div>
                        <div className='col-lg-6 col-6'>
                            <Dropdown label="Category 1"  ></Dropdown>
                        </div>
                        <div className='col-lg-6 col-6'>
                            <Dropdown label="Category 2" disabled={true}></Dropdown>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <TextArea label="Narrative" row="3"></TextArea>
                        </div>

                    </div>
                </div>
            </Modal>
            {showAddTask?<Addtask hide={()=> setAddTask(false)} caseID={caseID}></Addtask>:null}
        </div>
    )
} const NavWrapper = styled.div`
margin-top: 10px;
`;
