import React from 'react'
import Tab from '../../components/controls/tab'
import ActiveListGrid from '../../components/data-grid/active-list-grid/active-list-grid'
import CaseRecentGrid from '../../components/data-grid/case-recent-grid/case-recent-grid'
import CaseSearch from "./case-search";
export default function HomesSidebarTab({onSelectedViewChange ,caseSearchData}) {
  return (
            <Tab tabContent={<><div className="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><CaseSearch onSelectedViewChange={onSelectedViewChange} caseSearchData={caseSearchData}></CaseSearch></div>
                <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><ActiveListGrid></ActiveListGrid></div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab"><CaseRecentGrid></CaseRecentGrid></div>
                <div className="tab-pane fade" id="nav-nonstdrates" role="tabpanel" aria-labelledby="nav-contact-tab"></div> </>}>

                <button className="nav-link " id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Search</button>
                <button className="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Cases</button>
                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Recent</button>
                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-nonstdrates" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Dashboards</button>
                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-nonstdrates" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">beA Dashboard</button>
            </Tab>
  )
}

