import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import Button from '../controls/button'
import Modal from './custom-modal'
import TaskForm from './task-form-modal'
export default function EditTask(props) {
  return (
    <div>
    <Modal icon={<FontAwesomeIcon icon={faPenToSquare} />} hide={props.hide} title="Edit Task"  className="modal-dialog  modal-lg " footer={
        <>
            <Button size='medium' variant='outlined' name='Close' onClick={props.hide}></Button>
            <Button type='submit' size='medium' variant='contained' name='Edit Task'></Button>
        </>
    }>
    <TaskForm Due_Date={props.Due_Date} Task={props.Task} CaseId={props.CaseId}></TaskForm>
    </Modal>
</div>
  )
}
