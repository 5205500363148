import React from 'react'
import CustomGrid from '../../controls/grid';
import Modal from '../../modals/custom-modal';
import Button from '../../controls/button';
import { Column,ColumnFixing } from 'devextreme-react/data-grid';
export default function ExistingEntitiesGrid({ data,onCloseClick,onRowDoubleClick }) {

  const handleRowDoubleClick = (rowData) => {
    // Call the callback function with the selected column data
    onRowDoubleClick(rowData);
    onCloseClick();
  };
  return (
    <div>
      <Modal title="Existing Entities" className="modal-dialog modal-dialog-centered modal-lg" hide={onCloseClick} footer={
        <>
          <Button size='medium' variant='outlined' name='Close' onClick={onCloseClick}></Button>
          <Button type='submit' size='medium' variant='contained' name='Add New' ></Button>
        </>
      }>
        <h6>Existing Record(s) Found</h6>
        <p>The Following existing Witness records with smiliar name already exist Double-Click on a existing Witness record to use the details</p>
        <hr></hr>
        <div>
          <CustomGrid data={data} id="gridContainer" keyExpr="id" onRowDblClick={handleRowDoubleClick} >
            <ColumnFixing enabled={true} />
           <Column dataField="id" caption="ID" dataType="string" />
          <Column dataField="typeOfPerson" caption="Type Of Entity" dataType="string" />
          <Column dataField="dateofBirth" caption="Date Of Birth" dataType="date" />
          <Column dataField="addressBlock" caption="Address" dataType="string" />
          </CustomGrid>
        </div>
      </Modal>

    </div>
  )
}
