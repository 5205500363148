import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import ReportAnalysis from './report-submenu/report-analysis';
import ReportBilling from './report-submenu/report-billing';
import ReportOperational from './report-submenu/report-operational';
import ReportFinancial from './report-submenu/report-financial';
import UserReport from './report-submenu/report-user-report';
import { anchor, lists, navigation, subNavigation } from '../../utils/styles/subMenu-style';

export default function SubNavigationReport() {
    const [showSubNavAnalysis, setSubNavAnalysis] = useState(false)
    const [showSubNavBilling, setSubNavBilling] = useState(false)
    const [showSubNavOperational, setSubNavOperational] = useState(false)
    const [showSubNavFinancial, setSubNavFinancial] = useState(false)
    const [showSubNavUserReport, setSubNavUserReport] = useState(false)
    const [showSubNavReport, setSubNavReport] = useState(true)
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 1000);
        handleResize(); // Initial check
        if (!isSmallScreen) {
            setSubNavReport(true);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    return (
        <div>
            <SubNavWrapper>
                {showSubNavReport ?
                    <SubNav>
                        <ul className="navbar-nav me-auto">
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavAnalysis(!showSubNavAnalysis); if (isSmallScreen) { setSubNavReport(!showSubNavReport); } }}><i className="fa-solid fa-users fa-lg"></i> Analysis</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavBilling(!showSubNavBilling); if (isSmallScreen) { setSubNavReport(!showSubNavReport); } }}><i className="fa-solid fa-square-plus fa-xl"></i> Billing</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavOperational(!showSubNavOperational); if (isSmallScreen) { setSubNavReport(!showSubNavReport); } }}><i className="fa-solid fa-square-plus fa-xl"></i> Operational</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavFinancial(!showSubNavFinancial); if (isSmallScreen) { setSubNavReport(!showSubNavReport); } }}><i className="fa-solid fa-envelope fa-xl"></i> financial</Anchor></List>
                            <List><Anchor className="nav-link" href="#" onClick={() => { setSubNavUserReport(!showSubNavUserReport); if (isSmallScreen) { setSubNavReport(!showSubNavReport); } }}><i className="fa-solid fa-envelope-open-text fa-xl"></i> User Reports</Anchor></List>
                        </ul>
                    </SubNav>
                    : null}
                {showSubNavAnalysis ? <ReportAnalysis></ReportAnalysis> : null}
                {showSubNavBilling ? <ReportBilling></ReportBilling> : null}
                {showSubNavOperational ? <ReportOperational></ReportOperational> : null}
                {showSubNavFinancial ? <ReportFinancial></ReportFinancial> : null}
                {showSubNavUserReport ? <UserReport></UserReport> : null}
            </SubNavWrapper>
        </div>
    )
}
const SubNavWrapper = styled.div`
         ${navigation}
    `;
const SubNav = styled.div`
 ${subNavigation}
       
    `;
const List = styled.li`
     ${lists}
    `;
const Anchor = styled.a`
      ${anchor}
    `;
