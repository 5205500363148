import React, { useState } from 'react'
import styled from 'styled-components';
import Userimage from "../../assets/logos/user.png"
import { useAuth } from "../../hooks/useAuth";
import { logout } from "../../services/user-service";
import ChangePassword from '../modals/change-password';

export default function UserProfile() {
  const { onLogout } = useAuth();
  const { user } = useAuth();
  const [open] = useState(false)
  const [showChangePassword, setChangePassword] = useState(false)
  const onLogoutClick = async () => {
    await logout(user.userID);
    onLogout();
  };
  return (
    <div >
      <SubMenuewrap>
        <SubMenue>
          <UserInfo><UserInfoImage src={Userimage} /><UserName>Abdullah Dawood</UserName></UserInfo>
          <Line></Line>
          <div className='list-group' >
            <UnorderList>
              <UserList className="nav-item"><Anchor className="nav-link" ><Icon><i className="fa-solid fa-file-invoice fa-xl icon"></i></Icon> User Detail</Anchor> </UserList>
              <UserList className="nav-item"><Anchor className="nav-link" onClick={() => setChangePassword(!showChangePassword)}><Icon><i className="fa-solid fa-file-invoice fa-xl icon"></i></Icon> Change Password</Anchor></UserList>
              <UserList className="nav-item"><Anchor className="nav-link" onClick={onLogoutClick} ><Icon><i className="fa-solid fa-file-invoice fa-xl icon"></i></Icon>  Log Out</Anchor></UserList>
              {open ?
                <>
                  <UserList className="nav-item"> <Anchor className="nav-link" ><Icon><i className="fa-solid fa-file-invoice fa-xl icon"></i></Icon>  Start Shift</Anchor></UserList>
                  <UserList className="nav-item"> <Anchor className="nav-link" ><Icon><i className="fa-solid fa-file-invoice fa-xl icon"></i></Icon>  End Shift</Anchor></UserList>
                </> : null}
            </UnorderList>
          </div>
        </SubMenue>
      </SubMenuewrap>
      {showChangePassword ? <ChangePassword hide={() => setChangePassword(false)}></ChangePassword> : null}
    </div>
  )
}

const SubMenuewrap = styled.div`
   position :absolute;
   top: 100%;
   right: 3%;
   width: 320px;
   @media (max-width: 1000px) {
   position :relative; 
   width:100%;
   right:0%;
}
`;
const SubMenue = styled.div`
   background :  ${({ theme }) => theme.palette.nav.main};
   padding : 16px;
   margin : 2px;
   border-radius:2%;
   @media (max-width: 1000px) {
   padding : 0px;
}
`;
const UserInfo = styled.div`
   display: flex;
   align-item : center;
   justify-content: center;
   @media (max-width: 1000px) {
   padding-top : 20px;
}
`;
const UserName = styled.h4`
   color: ${({ theme }) => theme.palette.secondary.main};
`;
const UserInfoImage = styled.img`
    width: 40px;
    border-radius: 50%;
    margin-right : 15px;
`;
const Anchor = styled.a`
 border-radius:10px;
 color: ${({ theme }) => theme.palette.nav.textIcon};
    margin: 12px 0;
   &:hover {
    background-color:${({ theme }) => theme.palette.nav.onHoverBackground};
    color: ${({ theme }) => theme.palette.nav.onHoverTextIcon};
   transform: scale(1.02);
   .icon{
    background-color:${({ theme }) => theme.palette.nav.onHoverBackground};
    color: ${({ theme }) => theme.palette.nav.onHoverTextIcon};
   }
  }
    @media (max-width: 1000px) {
      display: flex;
  flex-direction: column;
text-align:center;
  width: 100px;
    margin:0;
}
  
    
`;
const Icon = styled.span`
    margin-right:10px;
    color: ${({ theme }) => theme.palette.nav.textIcon};
      @media (max-width: 1000px) {
}
`;
const Line = styled.hr`
   color:black;
`;

const UnorderList = styled.ul`
padding-left:0;
  list-style:none;
  @media (max-width: 1000px) {
  justify-content: space-between;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  padding:0;
}
`;
const UserList = styled.li`
 padding-bottom:8px;
 cursor:pointer;
  @media (max-width: 1000px) {
    display: inline-block;
    height: 100px;
}
`;