import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import TextArea from "../../controls/textarea";
import styled from "styled-components";
function CreateArea({ onAdd }) {
    const [isExpanded, setExpanded] = useState(false);

    const [note, setNote] = useState({
        content: ""
    });
    function handleChange(event) {
        const { name, value } = event.target;

        setNote(prevNote => {
            return {...prevNote,[name]: value};
        });
    }
    function submitNote() {
        onAdd(note);
        setNote({
            title: "",
            content: ""
        });
    }
    function expand() {
        setExpanded(true);
    }

    return (
        <div>
            <Form className="create-note">

                <TextWrapper >
                    <TextArea name="content" label="Make a Note" id="Task" onChange={handleChange} onClick={expand} value={note.content} row={isExpanded ? 3 : 1}></TextArea>
                </TextWrapper>
                <Zoom in={isExpanded}>
                    <ButtonFab  onClick={submitNote}>
                        <AddIcon />
                    </ButtonFab>
                </Zoom>
            </Form>
        </div>
    );
}
const TextWrapper = styled.div`
 background: ${({ theme }) => theme.palette.caseNote.main};
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 2px 5px #ccc;
    `;
const Form = styled.form`
height:150px;
 width: 100%;
  border: none;
  padding: 4px;
  outline: none;
  font-size: 1.2em;
  resize: none;
    `;
const ButtonFab = styled(Fab)`
 position:fixed;
bottom:68px;
left:90%;
cursor:pointer;
.css-ff8l2t-MuiButtonBase-root-MuiFab-root{}
background-color: #f5ba13;
}
@media (max-width: 1000px) {
    left:80%;
        }
      
     `;
export default CreateArea;
