import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UserProfile from "./user-profile";
import SubNavigationHome from "./sub-navigation-home";
import AdvanceSearchForm from "./advance-search-form";
import Userimage from "../../assets/logos/user.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Subnavigation_laa_billing from "./sub-navigation-laa-billing";
import SubNavigationReport from "./sub-navigation-report";
import SubNavigationTool from "./sub-navigation-tools";
import { CaseDetailTab } from "../case-detail/case-detail-tab";
export default function Navigation() {
  const [showSubNavHome, setShowSubNavHome] = useState(false)
  const [showSubNavLaaBill, setShowSubNavLaaBill] = useState(false)
  const [showSubNavHomeAdvanceSearch, setSubNavHomeAdvanceSearch] = useState(false)
  const [showSubNavHomeUserProfile, setSubNavHomeUserProfile] = useState(false)
  const [showSubNavReport, setSubNavReport] = useState(false)
  const [showSubNavTools, setSubNavTools] = useState(false)
  const [navigationItems, setNavigationItems] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [caseID, setCaseID] = useState("");
  
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1000);
    handleResize(); // Initial check
    if (!isSmallScreen) {
      setNavigationItems(true);
    }
    else {
      setSubNavHomeUserProfile(true)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  function HomeClick() {
    setShowSubNavHome(!showSubNavHome)
    if (isSmallScreen) {
      setShowBackButton(true)
      setNavigationItems(!navigationItems)
    }
  }
  function LAAClick() {
    setShowSubNavLaaBill(!showSubNavLaaBill)
    if (isSmallScreen) {
      setShowBackButton(true)
      setNavigationItems(!navigationItems)
    }
  }
  function ReportClick() {
    setSubNavReport(!showSubNavReport)
    if (isSmallScreen) {
      setShowBackButton(true)
      setNavigationItems(!navigationItems)
    }
  }
  function ToolsClick() {
    setSubNavTools(!showSubNavTools)
    if (isSmallScreen) {
      setShowBackButton(true)
      setNavigationItems(!navigationItems)
    }
  }
  function BackClick() {
    setShowSubNavHome(false)
    setSubNavReport(false);
    setShowSubNavLaaBill(false);
    setSubNavTools(false);
    setNavigationItems(!navigationItems)
    setShowBackButton(false)
  }
  const handleOtherFieldBlur = () => {
    // Make API call here
    CallApi();
  };
  const CallApi = async () => {
  
    CaseDetailTab(caseID);
  };
  const handleCaseIDChange = (event) => {
    setCaseID(event.target.value);
  };
  return (
    <div >
      <NavigationWrapper className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
            <span className="white-text"><i className="fas fa-bars fa-1x"></i></span>
          </Button>
          <SearchBox>
            <AdvanceSearchButton><SearchBoxIcon><FontAwesomeIcon icon={faMagnifyingGlass} /></SearchBoxIcon></AdvanceSearchButton>
            <Input type="text" placeholder="Case ID" name="Case ID"  onChange={handleCaseIDChange} onBlur={handleOtherFieldBlur}/>
            <AdvanceSearchButton onClick={() => setSubNavHomeAdvanceSearch(!showSubNavHomeAdvanceSearch)}><SearchBoxIcon><FontAwesomeIcon icon={faSliders} /></SearchBoxIcon></AdvanceSearchButton>
          </SearchBox>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <UnorderList className="navbar-nav ml-auto">
              {showSubNavHomeUserProfile ? <UserProfile></UserProfile> : null}
              {navigationItems ?
                <>
                  <List className="nav-item">
                    <Anchor className="nav-link" href="#" onClick={HomeClick}><i className="fa-solid fa-house fa-xl"></i> Home</Anchor>
                  </List>
                  <List className="nav-item">
                    <Anchor className="nav-link" href="#" onClick={ReportClick}><i className="fa-solid fa-file-invoice fa-xl"></i> Reports</Anchor>
                  </List>
                  <List className="nav-item">
                    <Anchor className="nav-link" href="#" onClick={LAAClick}><i className="fa-solid fa-money-bill fa-xl"></i> LAA Billing</Anchor>
                  </List>
                  <List className="nav-item">
                    <Anchor className="nav-link" href="#" onClick={ToolsClick}><i className="fa-solid fa-screwdriver-wrench fa-xl"></i> Tools</Anchor>
                  </List>
                </> : null}
              {showBackButton ?
                <BackButton className="nav-item">
                  <Anchor className="nav-link" href="#" onClick={BackClick}><FontAwesomeIcon icon={faArrowLeft} /> Back</Anchor>
                </BackButton> : null}
              {showSubNavHome ? <SubNavigationHome ></SubNavigationHome> : null}
              {showSubNavReport ? <SubNavigationReport></SubNavigationReport> : null}
              {showSubNavLaaBill ? <Subnavigation_laa_billing></Subnavigation_laa_billing> : null}
              {showSubNavTools ? <SubNavigationTool></SubNavigationTool> : null}
            </UnorderList>
          </div>
          <UserButton onClick={() => setSubNavHomeUserProfile(!showSubNavHomeUserProfile)}>
            <UserImage src={Userimage} />
            <Gear><FontAwesomeIcon icon={faGear} size="xl" /></Gear>
          </UserButton>
        </div>
      </NavigationWrapper>
      {showSubNavHomeAdvanceSearch ? <AdvanceSearchForm></AdvanceSearchForm> : null}
    </div>
  );
}
const NavigationWrapper = styled.nav`
  background: ${({ theme }) => theme.palette.nav.main};
  padding: 1rem 0;
  
`;
const Anchor = styled.a`
 word-spacing: 4px;
 padding-left:10px;
 border-radius:10px;
 
&:hover {
   background-color:${({ theme }) => theme.palette.nav.onHoverBackground};
   color: ${({ theme }) => theme.palette.nav.onHoverTextIcon};
   transform: scale(1.02);
  }
   color: ${({ theme }) => theme.palette.nav.textIcon};
`;
const Button = styled.button`
    color: ${({ theme }) => theme.palette.secondary.main};
`;
const List = styled.li`
 
padding-left:2rem;
@media (max-width: 1000px) {
    padding-bottom:16px;
    padding-left:0px;
  }
`;
const UnorderList = styled.ul`
   padding-left:20%;
   @media (max-width: 1000px) {
    padding-left:0px;
  }
`;
const UserImage = styled.img`
    width: 36px;
    border-radius: 50%;
    cursor:pointer;
`;
const UserButton = styled.div`
&:hover{
  background:#c0c0c0;
}
  &:focus {
    background :  ${({ theme }) => theme.palette.tertiary.main};
    transform: scale(1.10);
  }
  &:active {
    background :  ${({ theme }) => theme.palette.tertiary.main};
  }
  background: ${({ theme }) => theme.palette.secondary.main};
   border-radius: 25px;
   padding: 6px;
   width:6rem;
   @media (max-width: 1000px) {
    display:none;
    
  }
`;
const Gear = styled.i`
  padding-left:14px;
`;
const SearchBox = styled.div`
border-radius:10px;
border:2px solid #FFFFFF;
background: ${({ theme }) => theme.palette.secondary.main};
`;
const Input = styled.input`
border:none;
margin:8px 2px 8px 0px;
outline:none;
`;
const AdvanceSearchButton = styled.button`
background:transparent;
border:none;
border-radius:6px;
    justify-content: center;
    align-items: center;
    margin-right:6px;
&:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.nav.onHoverBackground};
    transform: scale(1.05);
  }
`;
const SearchBoxIcon = styled.i`
margin:0px 4px;
`;
const BackButton = styled.li`
display:none;
  @media (max-width: 1000px) {
    display:block;
  }
`;
