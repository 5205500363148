import React, { useState } from "react";
import Note from "./note-area";
import CreateArea from "./create-area";
import styled from "styled-components";

function HandleNote() {
  const [notes, setNotes] = useState([]);

  function addNote(newNote) {
    setNotes(prevNotes => [newNote, ...prevNotes]);
  }
  return (
    <DivWrapper>
      <CreateArea onAdd={addNote} />
      {notes.map((noteItem, index) => {
        return (
          <Note
            key={index}
            id={index}
            title={noteItem.title}
            content={noteItem.content}
          />
        );
      })}
    </DivWrapper>
  );
}
const DivWrapper = styled.div`
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  max-height:540px;
  overflow-y:auto;

  
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;
export default HandleNote;
