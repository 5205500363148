import React from 'react'
import styled from 'styled-components';
import TextInput from '../controls/textinput';
import Datepicker from '../controls/datepicker';
import Dropdown from '../controls/drop-down';
import Button from '../controls/button';
import SearchIcon from '@mui/icons-material/Search';

export default function AdvanceSearchForm() {
    return (
        <>
            <ContainerWrapper className='container-fluid'>
                <RowWrapper className='row g-3'>
                    <Heading><i className="bi bi-funnel"></i> Advance Search</Heading>
                    <hr></hr>
                    <Column className='col-lg-3 col-md-6 col-6 '>
                        <TextInput label="Case ID" id="Case-ID" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Secondary ID" id="Secondary-ID" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Claims Portal ID" id="Claims-Portal-ID" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="UFN" id="UFN" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Client Name" id="Client-Name" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Client Address" id="Client-Address" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Phone Number" id="Phone-Number" variant="standard" ></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Email Address" id="Email-Address" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <Datepicker label="Start Date" id="Start-Date"></Datepicker>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <Datepicker label="End Date" id="End-Date"></Datepicker>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Other Party Name" id="Other-Party-Name" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <TextInput label="Other Party Ref" id="Other-Party-Ref" variant="standard"></TextInput>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <Dropdown label="Key Field" id="Key-Field1" ></Dropdown>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <Dropdown label="Key Value" id="Key-Value1" ></Dropdown>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6'>
                        <Dropdown label="Key Field" id="Key-Field2" ></Dropdown>
                    </Column>
                    <Column className='col-lg-3 col-md-6 col-6 '>
                        <Dropdown label="Key Value" id="Key-Value2" ></Dropdown>
                    </Column>
                    <span><hr></hr></span>
                    <Column className='col-lg-6 col-md-6 col-6'>
                        <CancelButton><Button variant="outlined" name="Cancel" icon="bi bi-x-circle-fill" size="large"></Button></CancelButton>
                    </Column>
                    <Column className='col-lg-6 col-md-6 col-6'>
                        <SearchButton><Button variant="contained" name="Search" icon={<SearchIcon />} size="large"></Button></SearchButton>
                    </Column>
                </RowWrapper>
            </ContainerWrapper>
        </>
    )
}
const ContainerWrapper = styled.div`
padding: 0;
`;
const RowWrapper = styled.div`
   background :  ${({ theme }) => theme.palette.tertiary.main};
   padding : 20px;
   margin-right:0;
   @media (max-width: 1000px) {
}
`;
const SearchButton = styled.div`
 float:right;
  

@media (max-width: 1000px) {
   width:100%;
  }
  `;
const CancelButton = styled.div`
position:relative;
float:right;
left:75%;

@media (max-width: 1000px) {
   width:100%;
   left:0%;
  }
  `;
const Column = styled.div`

`;
const Heading = styled.h1`
 @media (max-width: 1000px) {
   text-align:center;
}
`;