import React, { useState, useEffect } from "react";
import Layout from "../../components/shared/layout";
import { createGlobalStyle } from 'styled-components';
import { useAuth } from "../../hooks/useAuth";
import CaseDuplicateAlert from "../../components/modals/alert-modals/case-duplicate-alert";
import HomesSidebarTab from "./home-sidebar-tab";
import CaseTaskGrid from "../../components/data-grid/case-task-grid/task-grid";
import CasesGrid from "../../components/data-grid/cases-data-grid/cases-grid";
import { homeCaseTask } from "../../utils/constants/application-areas";
import { userTask, limitation, portalDateLimit, notification } from "../../services/task-service";
import SplitPane from 'react-split-pane';
import "./splitter.css"
export default function HomeView(props) {
  const [caseID, setCaseID] = useState("");
  const [userTaskData, setUserTaskData] = useState("");
  const [showCaseAlert, setShowCaseAlert] = useState(false);
  const [selectedView, setSelectedView] = useState(""); // State to store the selected view
  const [withinDays, setwithinDays] = useState("true");
  const [viewCaseTaskGrid, setViewCaseTaskGrid] = useState(true);
 const [viewCaseSearchGrid, setViewCaseSearchGrid] = useState(false);
  const [caseSearchData, setCaseSearchData] = useState(null);
  const { user } = useAuth();
  const key = user.userID + homeCaseTask;
  useEffect(() => {
    // Call the userTask API when selectedView or withinDays changes
  
    if (selectedView === "Limitation Reminders") {
      CallLimitationApi();
    } else if (selectedView === "Claims Portal Date Limits") {
      CallPortalDateLimitApi();
    }
    else if (selectedView === "Notifications") {
      CallNotificationApi();
    }

    else {
      CallUserTaskApi();
    }
   
  }, [selectedView, withinDays]);
  const CallUserTaskApi = async () => {


    try {
      const usertask = await userTask(user.userID, withinDays);
      setUserTaskData(usertask.data._Tasks)
      changeView();
    }
    catch (error) {
     // Log any errors
    }
  };
  const CallLimitationApi = async () => {


    try {
      const userlimitation = await limitation();
      setUserTaskData(userlimitation.data._Tasks)
      changeView();
    }
    catch (error) {
     // Log any errors
    }
  };
  const CallPortalDateLimitApi = async () => {


    try {
      const portaldatelimits = await portalDateLimit();
      setUserTaskData(portaldatelimits.data._Tasks)
      changeView();
    }
    catch (error) {
     // Log any errors
    }
  };
  const CallNotificationApi = async () => {
    try {
      const usernotification = await notification();
      setUserTaskData(usernotification.data._Tasks)
      changeView();
    }
    catch (error) {
     // Log any errors
    }
  };

  const handleViewChange = (view) => {
    setSelectedView(view);
    if (view === "Your Tasks Overdue / due soon") {
      setwithinDays("true");
    }
    if (view === "Your Tasks due later") {
      setwithinDays("false");
    }


  };
  const changeView =()=>{
    setViewCaseTaskGrid(true);
    setViewCaseSearchGrid(false);
  }

  // Callback function to update the selected view
  const updateCaseSearchData = (data) => {
    setCaseSearchData(data);
    setViewCaseTaskGrid(!viewCaseTaskGrid);
    setViewCaseSearchGrid(!viewCaseSearchGrid);
  };
  return (
    <div>
      {showCaseAlert && <CaseDuplicateAlert hide={() => { setShowCaseAlert(!showCaseAlert) }} caseID={caseID}></CaseDuplicateAlert>}
      <GlobalStyle />
      <Layout>
        {/* Wrap the content in SplitPane */}
        <SplitPane split="vertical" defaultSize="30%" >
          <div >
            <HomesSidebarTab onSelectedViewChange={handleViewChange} caseSearchData={updateCaseSearchData}></HomesSidebarTab>
          </div>
          <div >
            {viewCaseTaskGrid &&<CaseTaskGrid taskData={userTaskData} homeView="true" storeKey={key}></CaseTaskGrid>}
            {viewCaseSearchGrid &&<CasesGrid searchData={caseSearchData}></CasesGrid>}
          </div>
        </SplitPane>
      </Layout>
    </div>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
    max-width: 100%;
  }
`;
