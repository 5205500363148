import React from 'react'
import styled from 'styled-components';
import { anchor, lists, navigation, subNavigation } from '../../utils/styles/subMenu-style';

export default function Subnavigation_laa_billing() {
    return (
        <div>
            <SubNavWrapper>
                <SubNav>
                    <ul className="navbar-nav me-auto">
                        <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-square-plus fa-xl"></i> Court Duty</Anchor></List>
                        <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-envelope fa-xl"></i> unconcluded transactions</Anchor></List>
                        <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-envelope-open-text fa-xl"></i> LAA Submissions</Anchor></List>
                        <List><Anchor className="nav-link" href="#"><i className="fa-solid fa-envelope-circle-check fa-xl"></i> Non Standard Fees</Anchor></List>
                    </ul>
                </SubNav>
            </SubNavWrapper>
        </div>
    )
}
const SubNavWrapper = styled.div`
        ${navigation}
    `;
const SubNav = styled.div`
${subNavigation}
    `;
const List = styled.li`
    ${lists}
    `;
const Anchor = styled.a`
     ${anchor}
    `;

