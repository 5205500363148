import React from 'react'
import CaseHistoryGrid from '../../components/data-grid/case-history-data-grid/case-history-grid'
import RichEditDocument from '../../components/controls/rich-edit'
import { useAuth } from '../../hooks/useAuth'
import { mainCaseHistory } from '../../utils/constants/application-areas'
export default function CaseHistorView() {
  const { user } = useAuth();
  const key = user.userID + mainCaseHistory;
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-11'>
           <CaseHistoryGrid storeKey={key}></CaseHistoryGrid> 
        </div>
        <div className='col-1'>
           <RichEditDocument></RichEditDocument>
        </div>
      </div>
    </div>
  )
}
