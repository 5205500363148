import Navigation from "../navigation/navigation";
import MaintainOrganizationView from "../../views/case-detail/maintain-organization-view";
import MaintainPersonView from "../../views/case-detail/maintain-person-view";

const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      <main>{children}</main>
{/* <MaintainPersonView></MaintainPersonView> */}
    </>
  );
};

export default Layout;
