import React from 'react'
import TextField from "@mui/material/TextField";

export default function TextInput(props) {
    const { width, height } = props;
    return (
        <div>
            {props.required ?
                <TextField
                    fullWidth
                    id={props.id}
                    label={props.label}
                    variant={props.variant}
                    value={props.value}
                    onChange={props.onChange}
                    type={props.type}
                    size="small"
                    required
                    onBlur={props.onBlur}
                    inputProps={{ pattern: props.regexPattern }}
                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{ width, height }} // Add width and height styles
                />
                : props.disabled ?
                    <TextField
                        fullWidth
                        id={props.id}
                        label={props.label}
                        variant={props.variant}
                        value={props.value}
                        onChange={props.onChange}
                        type={props.type}
                        size="small"
                        disabled
                        onBlur={props.onBlur}
                        inputProps={{ pattern: props.regexPattern }}
                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{ width, height }} // Add width and height styles
                    /> :
                    <TextField
                        fullWidth
                        id={props.id}
                        label={props.label}
                        variant={props.variant}
                        value={props.value}
                        onChange={props.onChange}
                        type={props.type}
                        helperText={props.helperText}
                        error={props.error}
                        size="small"
                        onBlur={props.onBlur}
                        inputProps={{ pattern: props.regexPattern }}
                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{ width, height }} // Add width and height styles
                    />
            }
        </div>
    )
}
