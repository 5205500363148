import { httpClient } from "./httpclient";

const url = "v1/setting";

const getSolicitorSetting = async (subdomain) => {
  // var endpoint = url + "/" + subdomain;
var endpoint = url ;
  return await httpClient.get(endpoint);
};

export { getSolicitorSetting };
