
import { MyProSidebarProvider } from "../navigation/side-navbar/sidebar-context";
const CaseDetailLayout = ({ children,responseData }) => {
    return (
        <>
            <MyProSidebarProvider>
                {children}
            </MyProSidebarProvider>
        </>
    );
};

export default CaseDetailLayout;
