import { httpClient } from "./httpclient";

const url = "v1/user";

const authenticateUser = async (userId, userPassword, section, ipAddress) => {
  try {
    var request = {
      id: userId,
      password: userPassword,
      section: section,
      ipAddress: ipAddress,
    };
    return await httpClient.post(url, request);
  } catch (error) {
    throw new Error(error.response.status);
  }
};

const resetPassword = async (userId) => {
  var request = {
    id: userId,
  };

  return await httpClient.post(url, request);
};

const logout = async (userId) => {
  var request = {
    id: userId,
  };

  const endpoint = url + "/logout";

  return await httpClient.post(endpoint, request);
};
const changePassword = async (userId,userOldPassword,userNewPassword) => {
  var request = {
    id: userId,
    oldPassword:userOldPassword,
    newPassword:userNewPassword
  };

  const endpoint = url + "/change";

  return await httpClient.post(endpoint, request);
};

export { authenticateUser, resetPassword, logout,changePassword };
