import { httpClient } from "./httpclient";
const url = "v1/case";

const caseDetail = async (id,completeexpiretaskonopen) => {
    try {
        const endpoint = `${url}/${id}/${completeexpiretaskonopen}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const activeList = async (userID) => {
    try {
        const endpoint = `${url}/activelist/${userID}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const recentList = async (userID) => {
    try {
        const endpoint = `${url}/recentlist/${userID}?withinDays=10`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const caseSearch = async (clientName) => {
    try {
        const endpoint =`${url}/search?ClientSurname=${clientName}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
export { caseDetail,activeList ,recentList,caseSearch};