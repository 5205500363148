import React, { useState } from 'react'
import Dropdown from '../../controls/drop-down';
import CheckBox from '../../controls/checkbox';
import Datepicker from '../../controls/datepicker';
import TextInput from '../../controls/textinput';
import styled from 'styled-components';
import ExistingEntitiesGrid from '../../data-grid/existing-entity-grid/existing-entities-grid';
import { personSearch } from '../../../services/entity-service';
export default function PersonPersonalDetail({ onClick, entityName, isNewPerson }) {
    const [isMaleChecked, setIsMaleChecked] = useState(false);
    const [isFemaleChecked, setIsFemaleChecked] = useState(false);
    const [textFieldsChanged, setTextFieldsChanged] = useState(false);
    const [showViewExistingEntities, setShowViewExistingEntities] = useState(false);
    const [surname, setSurname] = useState("");
    const [forename, setForename] = useState("");
    const [apiData, setApiData] = useState([]);
    const handleMaleCheckboxChange = (event) => {
        setIsMaleChecked(event.target.checked);
        setIsFemaleChecked(false); // Uncheck the "Female" checkbox
    };

    const handleFemaleCheckboxChange = (event) => {
        setIsFemaleChecked(event.target.checked);
        setIsMaleChecked(false); // Uncheck the "Male" checkbox
    };
    const handleForenameChange = (event) => {
        setForename(event.target.value);
        setTextFieldsChanged(true);
    };
    const handleSurnameChange = (event) => {
        setSurname(event.target.value);


    };
    const handleOtherFieldBlur = () => {
        if (textFieldsChanged) {
            // Make API call here
            CallApi();
        }
    };
    const CallApi = async () => {
        let person;
        try {
            const response = await personSearch(entityName, false, surname, forename);
            setTextFieldsChanged(false);
            person = response.data.people;
            setApiData(person);
            setShowViewExistingEntities(true);

        } catch (error) {
             // Log any errors
        }



    };
    const handleCloseButtonClick = () => {
        // Call the onClick function passed from the parent component
        setShowViewExistingEntities(!showViewExistingEntities)
        onClick();
      };
    return (
        <div>
            {showViewExistingEntities && <ExistingEntitiesGrid data={apiData} onCloseClick={handleCloseButtonClick} ></ExistingEntitiesGrid>}
            {!isNewPerson && <Dropdown label="Contacts" id="contacts" ></Dropdown>}
            <DivWrapper >
                <h6>Personal Details</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Title" id="title"  ></Dropdown>
                    </div>
                    <div className='col-lg-3 col-md-3 col-3'>
                        <CheckBox label="Male"
                            checked={isMaleChecked}
                            onChange={handleMaleCheckboxChange}></CheckBox>
                    </div>
                    <div className='col-lg-3 col-md-3 col-3'>
                        <CheckBox label="Female"
                            checked={isFemaleChecked}
                            onChange={handleFemaleCheckboxChange}></CheckBox>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Forenames(s)" id="forenames" variant="standard" onChange={handleForenameChange}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Surname" id="surname" variant="standard" onChange={handleSurnameChange} onBlur={handleOtherFieldBlur} ></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Suffix" id="suffix" variant="standard" ></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Datepicker label="D.O.B" id="dob" ></Datepicker>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;

