import React, { useState } from 'react'
import TextInput from '../controls/textinput'
import TextArea from '../controls/textarea'
import Dropdown from '../controls/drop-down'
import Datepicker from '../controls/datepicker'
import CheckBox from '../controls/checkbox'
import Button from '../controls/button'

export default function TaskForm(props) {
    const [Task, setTask] = useState(props.Task);
    const handleChange = (event) => {
        setTask(event.target.value);
      };
    const [showAdditionalInformation, setAdditionalInformation] = useState(false)
    return (
        <div>
            <form>
                <div className="row g-3">

                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Assign to" id="Assign-to" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Case ID" id="Task-case-Id" variant="standard" value={props.CaseId}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <Dropdown label="Standard items" id="Standard-items" ></Dropdown>
                    </div>
                    <div className='col-lg-12'>
                        <TextArea label="Task" id="Task" row="3"  onChange={handleChange} value={Task}></TextArea>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Status" id="Status" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Priority" id="Priority" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Datepicker label="Start Date" id="Start-Date"></Datepicker>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Datepicker label="End Date" id="End-Date"></Datepicker>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6' onClick={() => setAdditionalInformation(!showAdditionalInformation)}>
                        <Button name="Additional Information" variant="text"></Button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <CheckBox label="Auto-Complete On Open"></CheckBox>
                    </div>
                    {showAdditionalInformation ?
                        <>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <Dropdown label="Added" id="Added" disabled="true" ></Dropdown>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <TextInput label="Disabled" variant="standard" disabled="true"></TextInput>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <Dropdown label="Completed" id="Completed" disabled="true"></Dropdown>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <TextInput label="Disabled" variant="standard" disabled="true"></TextInput>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <Dropdown label="Deleted" id="Deleted" disabled="true"></Dropdown>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <TextInput label="Disabled" variant="standard" disabled="true"></TextInput>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <CheckBox label="Add to Calender"></CheckBox>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <TextInput label="Shared Calender" id="Shared-Calender" variant="standard" ></TextInput>
                            </div>
                            <div className='col-lg-12'>
                                <TextArea label="Appointment Notes" id="Appointement-Notes" row="3"></TextArea>
                            </div>
                        </> : null}
                </div>
            </form>
        </div>
    )
}
