import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../hooks/useAuth";

function Note(props) {
  const { user } = useAuth();
  var date = new Date();
  var currentDate = date.toLocaleString();
  var data = currentDate + " (" + user.userID + ")"
  return (
        <CardBody className="card">
          <div className="card-body">
            <h6>{data}</h6>
            <P>{props.content}</P>
          </div>
        </CardBody>
  );
}
const CardBody = styled.div`
    background: ${({ theme }) => theme.palette.caseNote.main};
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
       `;
const P = styled.p`
    margin-bottom:0;
          `;

export default Note;
