import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function Dropdown({ id, label, options, disabled, onChange, value }) {
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                {disabled ?
                    <Select
                        id={id}
                        label={label}
                        variant="standard"
                        size="small"
                        onChange={onChange}
                        value={value}
                        disabled
                    >
                    </Select>
                    :
                    <Select
                        id={id}
                        label={label}
                        variant="standard"
                        size="small"
                        onChange={onChange}
                        value={value}
                        defaultValue={value}
                    >
                        {options?.map(option => (
                            <MenuItem key={option.id} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                }
            </FormControl>
        </div>
    )
}
