import React from 'react'
import Dropdown from '../controls/drop-down'
import Datepicker from '../controls/datepicker'
import branch from '../json/branch'
import Casetype from '../json/Casetype'
import CaseSubTypeCrime from '../json/Case-sub-type-Crime'
import CaseSubTypePersonalInjuries from '../json/Case-sub-type-Personal-Injury'
import FeeEarner from '../json/Fee-Earner'
import Supervisor from '../json/supervisor'
import Button from '../controls/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from './custom-modal'
import { useState } from 'react'

export default function AddCases(props) {
    const [showCaseSubTypeCrime, setShowCaseSubTypeCrime] = useState(false)
    const [showDropDownPersonalInjuries, setShowDropDownPersonalInjuries] = useState(false)
    const [showClientStaus, setClientStatus] = useState(true)
    const [showDateOfAccident, setDateOfAccident] = useState(true)
    const [showOICPortal, setOICPortal] = useState(true)
    const [showClaimValue, setClaimValue] = useState(true)
    const [showReferalSource, setReferalSource] = useState(true)
    const [CaseTypes, setCaseTypes] = useState("")
    const [CaseSubTypes, setCaseSubTypes] = useState("")
    const handleCaseType = (event) => {
        const CaseTypes = event.target.value;
        setCaseTypes(CaseTypes);
        if (CaseTypes === "Crime") {
            setShowCaseSubTypeCrime(true);
            setShowDropDownPersonalInjuries(false)
        }
        else if (CaseTypes === "Personal Injury") {
            setShowDropDownPersonalInjuries(true);
            setShowCaseSubTypeCrime(false)
        }
        else {
            setShowCaseSubTypeCrime(false)
            setShowDropDownPersonalInjuries(false)
        }
    };
    const handleCaseSubType = (event) => {
        const CaseSubTypes = event.target.value;
        setCaseSubTypes(CaseSubTypes);
        if (CaseSubTypes === "Pedistrian Accident" || CaseSubTypes === "Holiday (Accident)" || CaseSubTypes === "Holiday (Illness)" || CaseSubTypes === "Medical Negligence" || CaseSubTypes === "Criminal Injuries" || CaseSubTypes === "Other") {
            setClientStatus(false)
            setDateOfAccident(false)
            setOICPortal(false)
            setReferalSource(false)
        } else if (CaseSubTypes === "Public Liability" || CaseSubTypes === "Employer's Liability (Accident)") {
            setClientStatus(false)
            setReferalSource(false)
            setDateOfAccident(true)
            setOICPortal(true)
            setClaimValue(true)
        } else if (CaseSubTypes === "Employer's Liability (Disease)") {
            setClientStatus(false)
            setReferalSource(false)
            setDateOfAccident(false)
            setOICPortal(true)
            setClaimValue(true)
        }
        else {
            setClientStatus(true)
            setDateOfAccident(true)
            setOICPortal(true)
            setReferalSource(true)
            setClaimValue(true)
        }
    };
    const dataCaseSubType = showCaseSubTypeCrime ? CaseSubTypeCrime : showDropDownPersonalInjuries ? CaseSubTypePersonalInjuries : null;
    return (
        <>
            <Modal icon={<FontAwesomeIcon icon={faPlusCircle} />} hide={props.hide} title="Add Case" className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={props.hide}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Add Case'></Button>
                </>
            }>
                <form>
                    <div className="row g-4">
                        <div className='col-lg-12'>
                            <Dropdown label="Branch" id="Branch" options={branch} ></Dropdown>
                        </div>
                        <div className='col-lg-6 col-md-6 col-6'>
                            <Dropdown label="Case Type" id="Case-Type" options={Casetype} onChange={handleCaseType} value={CaseTypes}></Dropdown>
                        </div>
                        <div className='col-lg-6 col-md-6 col-6'>
                            <Dropdown label="Case Sub Type" id="Case-Sub-Type" options={dataCaseSubType} onChange={handleCaseSubType} value={CaseSubTypes}></Dropdown>
                        </div>
                        <div className='col-lg-6 col-md-6 col-6'>
                            <Dropdown label="Fee Earner  " id="Fee-Earner" options={FeeEarner}></Dropdown>
                        </div>
                        <div className='col-lg-6 col-md-6 col-6'>
                            <Dropdown label="Supervisor" id="Supervisor" options={Supervisor} ></Dropdown>
                        </div>
                        {showDropDownPersonalInjuries ?
                            <>
                                {showClientStaus ?
                                    <div className='col-lg-12'>
                                        <Dropdown label="Client Status" id="Client-Status" ></Dropdown>
                                    </div>
                                    : null}
                                {showDateOfAccident ?
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <Datepicker label="Date Of Accident" id="Accident-Date" ></Datepicker>
                                    </div>
                                    : null}
                                {showOICPortal ?
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <Dropdown label="Claims Or OIC Portal?" id="OIC-Portal" ></Dropdown>
                                    </div>
                                    : null}
                                {showClaimValue ?
                                    <div className='col-lg-12'>
                                        <Dropdown label="Approx Overall Claim Value" id="Claim-value" ></Dropdown>
                                    </div>
                                    : null}
                                {showReferalSource ?
                                    <div className='col-lg-12'>
                                        <Dropdown label="Referral Source" id="Referral-Source" ></Dropdown>
                                    </div>
                                    : null}
                            </>
                            : null}
                        <div className='col-lg-12'>
                            <Dropdown label="Case Source" id="Case-Source" ></Dropdown>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}
