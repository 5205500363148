import React, { useState } from 'react'
import TextInput from '../controls/textinput'
import { useAuth } from '../../hooks/useAuth'
import { changePassword } from '../../services/user-service'
import PasswordStrengthIndicator from '../password-strength-meter/password-strength-indicator'
import Button from '../controls/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import Modal from './custom-modal'
export default function ChangePassword(props) {
    const { user } = useAuth();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const handleChangePassword = async (event) => {
        event.preventDefault();
        if (!oldPassword || !newPassword) {
            return;
        }
        else {
            try {
                var response = await changePassword(
                    user.userID,
                    oldPassword,
                    newPassword,
                );
                alert(response.data);

            } catch (error) {
                
            }
        }
    }
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setNewPassword(newPassword);
        if (confirmPassword && newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError('');
        }
    };
    const handleConfirmPasswordChange = (event) => {
        const confirmPassword = event.target.value;
        setConfirmPassword(confirmPassword);

        if (newPassword && confirmPassword !== newPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError('');
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        // You can perform additional validation or checks here if needed
        // Call the handleChangePassword function to handle the form submission
        handleChangePassword(event);
    };
    return (
        <>
            <Modal icon={<FontAwesomeIcon icon={faKey} />} hide={props.hide} title="Change Password" className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={props.hide}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Submit New Password' onClick={handleSubmit}></Button>
                </>
            }>
                <form>
                    <div className="row ">
                        <div className='col-lg-12 mb-4'>
                            <TextInput label="Old Password" id="Old-Password" variant="standard" onChange={e => setOldPassword(e.target.value)} ></TextInput>
                        </div>
                        <div className='col-lg-12 mb-1'>
                            <TextInput label="New-Password" id="New-Password" variant="standard" error={!!passwordError} helperText={passwordError} onChange={handlePasswordChange} ></TextInput>
                            <div className='form-group mt-1 '>
                                <PasswordStrengthIndicator Password={newPassword}></PasswordStrengthIndicator>
                            </div>
                        </div>
                        <div className='col-lg-12 mb-2'>
                            <TextInput label="Confirm Password" id="Confirm-Password" variant="standard" error={!!passwordError} helperText={passwordError} onChange={handleConfirmPasswordChange}></TextInput>
                            <div className='form-group mt-1 '>
                                <PasswordStrengthIndicator Password={confirmPassword}></PasswordStrengthIndicator>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

