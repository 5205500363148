import React  from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { PrimaryButton } from "../../../utils/styles/button-styles";
import {
  form,
  heading,
  icon,
  link,
} from "../../../utils/styles/shared-login-styles";

const ForgotPasword = ({
  userId,
  onBackToLogin,
  onUserIDChange,
  onForgetPassword,
}) => {
  return (
    <ForgotForm>
      <div className="col-sm-12">
        <Heading>Forgot Password</Heading>
        <hr />
        <form onSubmit={onForgetPassword}>
          <div className="form-group">
            <label htmlFor="formGroupInput"></label>
            <div className="input-group mb-4">
              <TextField
                id="outlined-basic formGroupInput"
                className="form-control"
                label="User ID"
                variant="outlined"
                value={userId}
                onChange={onUserIDChange}
                required
              />
              <Icon>
                <i className="fa-solid fa-id-card"></i>
              </Icon>
            </div>
          </div>
          <ReCAPTCHA sitekey="6LeepqIlAAAAAOA7d-nMvL-5kJkchCN4Tks4Mvdy" />
          <StyledButton
            variant="contained"
            className="w-100 mt-4 mb-4"
            type="submit"
          >
            Request New Password
          </StyledButton>
          <div onClick={onBackToLogin}>
            <BackToLogin> Back to Login</BackToLogin>
          </div>
        </form>
      </div>
    </ForgotForm>
  );
};
export default ForgotPasword;

const ForgotForm = styled.div.attrs({
  className: "container",
})`
  ${form}
`;
const Heading = styled.h1`
  ${heading}
`;
const Icon = styled.span.attrs({
  className: "input-group-text ",
})`
  ${icon}
`;
const BackToLogin = styled.p.attrs({
  className: "text-right",
})`
  ${link}
`;
const StyledButton = styled(Button)`
  ${PrimaryButton}
`;
