import React, { useState } from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import Addtask from '../modals/add-task-modal';
import AddHistoryConfirmation from '../modals/add-history-confirmation-modal';
import LinkedCase from '../modals/linked-case-modal';
import FileNoteModal from '../modals/file-note-modal';
import SendSMSModal from '../modals/send-sms-modal';
import { Link } from "react-router-dom";
export default function CaseDetailNavigation({ hide, showLetterGrid }) {
  const [showHistoryModal, setHistoryModal] = useState(false);
  const [showLinkedCaseModal, setLinkedCaseModal] = useState(false);
  const [showTaskForm, setTaskForm] = useState(false);
  const [showFileNote, setFileNote] = useState(false);
  const [showSendSMS, setSendSMS] = useState(false);
  return (
    <div>
      <SubNavWrapper className="navbar navbar-expand-lg">
        <SubNav>
          <ul className="navbar-nav ml-auto">
            <List><Anchor className="nav-link" href="#" onClick={hide} ><FontAwesomeIcon icon={faXmark} size="xl" /> <Span className="nav-text">Users</Span></Anchor></List>
            <List ><Anchor className="nav-link" href="#" onClick={() => { setHistoryModal(!showHistoryModal) }}><FontAwesomeIcon icon={faFloppyDisk} size="xl" /><Span className="nav-text">Users</Span></Anchor></List>
            <List><Anchor className="nav-link" href="#" ><FontAwesomeIcon icon={faClock} size="xl" /> <Span className="nav-text">Users</Span></Anchor></List>
            <List><Anchor className="nav-link" href="#" onClick={() => { setTaskForm(!showTaskForm) }}><i className="fa-solid fa-square-plus fa-xl"></i> <Span className="nav-text">Users</Span></Anchor></List>
            <List><Anchor className="nav-link" href="#" onClick={() => { setFileNote(!showFileNote) }}><i className="fa-solid fa-envelope fa-xl"></i> <Span className="nav-text">Users</Span></Anchor></List>
            <List><Anchor className="nav-link" href="#" onClick={() => { setSendSMS(!showSendSMS) }}><i className="fa-solid fa-envelope-open-text fa-xl"></i> <Span className="nav-text">Users</Span></Anchor></List>
            <List>
              <Linked to="/casedetail/CaseLetter" className="nav-link" onClick={showLetterGrid}>
                <i className="fa-solid fa-envelope-circle-check fa-xl"></i> <Span className="nav-text">Users</Span>
              </Linked>
            </List>
            <List><Anchor className="nav-link" href="#" onClick={() => { setLinkedCaseModal(!showLinkedCaseModal) }}><i className="fa-solid fa-envelope-circle-check fa-xl"></i> <Span className="nav-text">Users</Span></Anchor></List>
          </ul>
        </SubNav>
      </SubNavWrapper>
      {showHistoryModal ? <AddHistoryConfirmation close={() => { setHistoryModal(!showHistoryModal) }}></AddHistoryConfirmation> : null}
      {showLinkedCaseModal ? <LinkedCase close={() => { setLinkedCaseModal(!showLinkedCaseModal) }}></LinkedCase> : null}
      {showTaskForm ? <Addtask hide={() => { setTaskForm(!showTaskForm) }}></Addtask> : null}
      {showFileNote ? <FileNoteModal hide={() => { setFileNote(!showFileNote) }}></FileNoteModal> : null}
      {showSendSMS ? <SendSMSModal hide={() => { setSendSMS(!showSendSMS) }}></SendSMSModal> : null}
    </div>
  )
}
const SubNavWrapper = styled.div`
     
   top: 100%;
   right: 0%;
   width: 100%;
   padding-top:3px;
   display: flex;
  align-items: center;
  justify-content: center;
   @media (max-width: 1000px) {
    transform: translate(-10px, -50%);
    display:block;
    position: absolute;
    top: 50%;
    right: 0;
    text-align:center;
    width: 8%;
    z-index: 1;
   }
   
`;
const SubNav = styled.div`
  border-radius:16px;
      background :  ${({ theme }) => theme.palette.subMenu.main};
      padding : 10px 10px 10px 0px;
   @media (max-width: 1000px) {
    margin-left:0;
    padding : 10px 0px 10px 0px;
        background :  ${({ theme }) => theme.palette.subMenu.main};
      }

`;
const List = styled.li`

@media (max-width: 1000px) {
    padding-bottom:16px;
    padding-left:0px;
  }
padding-left:1rem;
`;
const Anchor = styled.a`
    color: ${({ theme }) => theme.palette.subMenu.textIcon};
   padding-left:10px;
   border-radius:10px;
   &:hover {
   background-color:${({ theme }) => theme.palette.subMenu.onHoverBackground};
   color: ${({ theme }) => theme.palette.subMenu.onHoverTextIcon};
   transform: scale(1.02);
  }
   @media (max-width: 1000px) {
    color: ${({ theme }) => theme.palette.subMenu.textIcon};
    padding-left:0px;
      }

`;

const Span = styled.span`
display:none;

`;


const Linked = styled(Link)`
  color: ${({ theme }) => theme.palette.subMenu.textIcon};
  padding-left: 10px;
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.subMenu.onHoverBackground};
    color: ${({ theme }) => theme.palette.subMenu.onHoverTextIcon};
    transform: scale(1.02);
  }

  @media (max-width: 1000px) {
    color: ${({ theme }) => theme.palette.subMenu.textIcon};
    padding-left: 0px;
  }
`;

