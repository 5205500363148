import { httpClient } from "./httpclient";
const url = "v1/task";

const userTask = async (userID,withinDays) => {
    try {
        const endpoint = `${url}/user/${userID}?thisWeek=${withinDays}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const limitation = async () => {
    try {
        const endpoint = `${url}/limitation`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const portalDateLimit = async (userID) => {
    try {
        const endpoint = `${url}/portaldatelimits`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const notification = async () => {
    try {
        const endpoint = `${url}/portalnotifications`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
export { userTask, limitation, portalDateLimit, notification };