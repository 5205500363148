import React ,{useState}from 'react'
import PersonalDetail from '../../components/dynamic-entity/entity-type-t/entity-type-t-form-b/personal-detail'
import Address from '../../components/dynamic-entity/entity-type-t/entity-type-t-form-b/address'
import ContactDetail from '../../components/dynamic-entity/entity-type-t/entity-type-t-form-b/contact-detail'
import CaseReference from '../../components/dynamic-entity/entity-type-t/entity-type-t-form-b/case-reference'
import AdditionalInformation from '../../components/dynamic-entity/additional-information'
import CaseDetailLayout from '../../components/shared/case-detail-layout';
import styled from 'styled-components'
export default function EntityTypeTFormBView( {entityType ,entityName, detailIndex, caseID, orgID, entityID,casePersonID } ) {
  const [title, setTitle] = useState("");
  const [forename, setForename] = useState("");
  const [surname, setSurname] = useState("");
  const [houseID, setHouseID] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [district, setDistrict] = useState("");
  const [town, setTown] = useState("");
  const [postcode, setPostCode] = useState("");
  const handleExistingEntityDoubleClick = (selectedData) => {
    // Do something with the selected column data
    const data = selectedData.data;
    setTitle(data.title);
    setForename(data.forename);
    setSurname(data.surname);
    setHouseID(data.houseID);
    setStreet1(data.street1);
    setStreet2(data.street2);
    setDistrict(data.district);
    setTown(data.town);
    setPostCode(data.postcode);
  };
  return (
      <div className="row">
        <div className='col-lg-6 col-md-6 col-12'>
          <Container>
            <PersonalDetail entityType={entityType} caseID={caseID} entityID={entityID} entityName={entityName} detailIndex={detailIndex} orgID={orgID} onRowDoubleClick={handleExistingEntityDoubleClick} apiTitle={title} apiForename={forename} apiSurname={surname}></PersonalDetail>
            <Address apiHouseID={houseID} apiStreet1={street1} apiStreet2={street2} apiDistrict={district} apiTown={town} apiPostCode={postcode}></Address>
            <ContactDetail></ContactDetail>
            <CaseReference></CaseReference>
          </Container>
        </div>
        <div className='col-lg-6 col-md-6 col-12'>
          <Container>
            <AdditionalInformation entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID}></AdditionalInformation>
          </Container>
        </div>
      </div>

  )
}
const Container = styled.div`
margin: 10px;
border: 1px solid #ddd;
border-radius: 5px;
padding: 10px 10px 10px 10px;

height:600px;
overflow-y: auto;
::-webkit-scrollbar {
width: 8px;
background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
background-color: #888;
border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
background-color: #555;
}
`;